/**
 * DreamDescription Component
 * Allows users to input a detailed description of their dream event
 * Features:
 * - Real-time keyword detection for contextual suggestions
 * - Progress bar with encouraging messages
 * - Example cards with copyable content
 * - Auto-save to localStorage
 * - Animated suggestions based on keywords
 */

// Import required dependencies for routing, animations, icons and styling
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Sparkles, Rocket } from 'lucide-react';
import '../components/DreamDescription.css';
import ProgressTracker from '../components/ProgressTracker';
import MainLayout from '../layouts/MainLayout';
import logger from '../utils/logger';

export default function DreamDescription() {
  const navigate = useNavigate();
  // Core state management for dream description and UI interactions
  const [dreamDescription, setDreamDescription] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [visibleSuggestions, setVisibleSuggestions] = useState([]);
  const [shownKeywords, setShownKeywords] = useState(new Set());
  const [expandedCard, setExpandedCard] = useState(null);

  // Load saved dream description from localStorage on component mount if it exists
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    if (savedData.dream) {
      setDreamDescription(savedData.dream);
    }
  }, []);

  // Handle animation timing - starts after 10 seconds of content
  useEffect(() => {
    let timer;
    if (dreamDescription.length > 0 && !isAnimating) {
      timer = setTimeout(() => {
        setIsAnimating(true);
      }, 10000); // 10 second delay
    } else if (dreamDescription.length === 0) {
      setIsAnimating(false);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [dreamDescription, isAnimating]);

  // Log debug information about current route and stored data
  useEffect(() => {
    logger.group('Dream Description Page');
    logger.info('Current Route', window.location.pathname);
    logger.info('Stored Data', JSON.parse(localStorage.getItem('eventDetails')));
    logger.groupEnd();
  }, []);

  // Auto-hide suggestions after 30 seconds
  useEffect(() => {
    // Clear suggestions after 30 seconds
    const timer = setTimeout(() => {
      setVisibleSuggestions([]);
    }, 30000);

    return () => clearTimeout(timer);
  }, [visibleSuggestions]);

  // Handle form submission - saves to localStorage and navigates to next page
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAnimating(false);
    const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    
    logger.info('Saving dream description');
    localStorage.setItem('eventDetails', JSON.stringify({
      ...existingDetails,
      dream: dreamDescription
    }));
    
    navigate('/inspiration');
  };

  // Dictionary of suggestions based on event type keywords
  const keywordSuggestions = {
    'birthday': [
      'Open bar, drink tickets, or bar minimum? Signature cocktail for the VIP?',
      'Need a cool photo spot for group shots or the gram?',
      'Space for gifts or a champagne toast setup?'
    ],
    'wedding': [
      'Private spot for getting ready or a first look?',
      'Chill zone for guests who need a break from the dance floor?',
      'Indoor/outdoor options for unpredictable weather?'
    ],
    'business': [
      'Wi-Fi, chargers, or presentation setup ready to go?',
      'Want to deck out the space with your logo or branding?',
      'Breakout areas for quick meetings or quiet calls?'
    ],
    'celebration': [
      'High-tops for mingling, lounge seating, or a mix of both?',
      'Space to eat first and dance later?',
      'Different zones for chilling and partying?'
    ],
    'fundraiser': [
      'Silent auction or raffle setup needed?',
      'Check-in area for donations or pledges?',
      'Presentation space for cause awareness?'
    ],
    'graduation': [
      'Photo area with school colors or backdrop?',
      'Space for diplomas and award presentations?',
      'Family-style seating or standing reception?'
    ],
    'shower': [
      'Cute spot for games or an Instagram-worthy gift display?',
      'Need an accessible layout for strollers or grandparents?',
      'Looking for a cozy photo-ready space for the guest of honor?'
    ],
    'dinner': [
      'Family-style, plated service, or buffet setup?',
      'Private room for speeches, toasts, or announcements?',
      'Background music or conversation-friendly vibes?'
    ],
    'networking': [
      'Check-in area or name tag setup for smooth intros?',
      'High-tops for mingling or some seated areas too?',
      'Zones for different industries or interests?'
    ],
    'holiday': [
      'Gift exchange setup or white elephant area?',
      'Festive decor—your style or the venue\'s?',
      'Seasonal cocktails or a cozy hot chocolate bar?'
    ]
  };

  // Mapping of similar keywords to main event types
  const keywordMappings = {
    'corporate': 'business',
    'company': 'business',
    'work': 'business',
    'office': 'business',
    'conference': 'business',
    'seminar': 'business',
    'workshop': 'business',
    'retreat': 'business',
    'party': 'celebration',
    'bash': 'celebration',
    'gathering': 'celebration',
    'get-together': 'celebration',
    'reception': 'celebration',
    'soiree': 'celebration',
    'gala': 'fundraiser',
    'benefit': 'fundraiser',
    'charity': 'fundraiser',
    'auction': 'fundraiser',
    'nonprofit': 'fundraiser',
    'ngo': 'fundraiser',
    'commencement': 'graduation',
    'convocation': 'graduation',
    'grad': 'graduation',
    'bridal': 'shower',
    'baby': 'shower',
    'wedding shower': 'shower',
    'meetup': 'networking',
    'mixer': 'networking',
    'social': 'networking',
    'mingle': 'networking',
    'networking event': 'networking',
    'christmas': 'holiday',
    'halloween': 'holiday',
    'thanksgiving': 'holiday',
    'nye': 'holiday',
    'new years': 'holiday',
    'new year': 'holiday',
    'easter': 'holiday',
    'valentines': 'holiday',
    "valentine's": 'holiday',
    'independence day': 'holiday',
    'july 4th': 'holiday',
    '4th of july': 'holiday',
    'memorial day': 'holiday',
    'labor day': 'holiday',
    'hanukkah': 'holiday',
    'chanukah': 'holiday',
    'diwali': 'holiday',
    'lunar new year': 'holiday',
    'lunch': 'dinner',
    'brunch': 'dinner',
    'breakfast': 'dinner',
    'dining': 'dinner',
    'feast': 'dinner',
    'banquet': 'dinner'
  };

  // Check text input for keywords and show relevant suggestions
  const checkForSuggestions = (text) => {
    const lowerText = text.toLowerCase();
    const words = lowerText.split(/\s+/);
    
    if (lowerText.includes('birthday')) {
      if (!shownKeywords.has('birthday')) {
        setVisibleSuggestions(keywordSuggestions['birthday']);
        setShownKeywords(prev => new Set([...prev, 'birthday']));
        return;
      }
    }
    
    words.forEach(word => {
      const mappedKeyword = keywordMappings[word];
      if (mappedKeyword && !shownKeywords.has(mappedKeyword)) {
        setVisibleSuggestions(keywordSuggestions[mappedKeyword]);
        setShownKeywords(prev => new Set([...prev, mappedKeyword]));
      }
      
      if (keywordSuggestions[word] && !shownKeywords.has(word)) {
        setVisibleSuggestions(keywordSuggestions[word]);
        setShownKeywords(prev => new Set([...prev, word]));
      }
    });
  };

  // Clear all active suggestions at once
  const handleDismissSuggestions = () => {
    setVisibleSuggestions([]);
  }; 

  // Handle text input changes and trigger keyword detection
  const handleDescriptionChange = (e) => {
    setDreamDescription(e.target.value);
    checkForSuggestions(e.target.value);
  };

  // Example event descriptions that users can copy
  const exampleCards = [
    {
      id: 'birthdayParty',
      icon: '🎂',
      title: 'Birthday Party',
      description: 'Williamsburg, 50 people',
      examples: [`Birthday party for 50 friends in Williamsburg. Space: Full venue rental, standing room only with scattered high-tops. Vibe: Laid-back but stylish with exposed brick, vintage furniture, and dark moody lighting. Bar: Open bar package with premium liquor, craft cocktails, and beer. Food: No food needed - brought in outside cake they sliced for us. Extras: Outdoor space or rooftop access, plus brought own DJ. Budget: $6-8K, smart casual dress code.`]
    },
    {
      id: 'bridalShower',
      icon: '👰',
      title: 'Bridal Shower',
      description: 'SoHo, 35 people | Rachel',
      examples: [`Bridal shower for 35 guests in SoHo. Space: Private dining room with seated space for everyone. Vibe: Bright and airy with lots of natural light, marble details, and gold accents. Bar: Full service brunch with mimosa packages. Food: Light brunch menu with quiches, pastries, all vegetarian-friendly. Extras: Tea service, photo backdrop, and used our iPhone playlist. Budget: $8-10K all inclusive.`]
    },
    {
      id: 'companyHappyHour',
      icon: '🍻',
      title: 'Work Happy Hour',
      description: 'Midtown, 25 people',
      examples: [`Work happy hour for 25 people in Midtown. Space: Semi-private space or reserved area with mix of high-tops and lounge seating, plus standing room. Vibe: Modern and energetic with industrial touches and mood lighting. Bar: $3K minimum with premium well drinks, craft beer, and wine. Food: Light bites - sliders and flatbreads with vegetarian options. Extras: Pool tables or arcade games, venue's playlist was fine. Budget: $5-6K total.`]
    },
    {
      id: 'holidayParty',
      icon: '🎄',
      title: 'Work Holiday Party',
      description: 'Downtown, 150 people',
      examples: [`Work holiday party for 150 people downtown. Space: Full venue buyout with mix of lounge seating and cocktail tables. Vibe: Upscale and festive with crystal chandeliers, velvet seating, and warm lighting. Bar: $10K minimum for premium open bar with themed cocktails. Food: Heavy passed appetizers and stations with vegetarian options. Extras: Photo booth and lounge areas, used venue's sound system. Budget: $20-30K total.`]
    }
  ];

  // Handle expanding/collapsing example cards with smooth scroll
  const handleCardClick = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
    
    if (expandedCard !== cardId) {
      setTimeout(() => {
        const card = document.getElementById(`card-${cardId}`);
        if (card) {
          card.scrollIntoView({ 
            behavior: 'smooth',
            block: 'nearest'
          });
        }
      }, 100);
    }
  };

  // Component to render example cards with expandable details
  const ExampleCards = () => (
    <div className="space-y-3">
      <div className="text-center space-y-1">
        <p className="text-gray-600">Need inspiration? Click to see real event examples:</p>
        <p className="text-sm text-gray-500 italic">
          Click inside any expanded box to copy that example to your description
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
        {exampleCards.map((card) => (
          <div 
            key={card.id} 
            id={`card-${card.id}`}
            className="relative"
          >
            <button
              onClick={() => handleCardClick(card.id)}
              className={`w-full text-left p-3 sm:p-4 rounded-lg transition-all duration-200 
                ${expandedCard === card.id 
                  ? 'border-2 border-purple-500 shadow-md bg-white' 
                  : 'border-2 border-purple-300 hover:border-purple-500 bg-white'} 
                hover:shadow-md`}
            >
              <div className="flex items-start justify-between">
                <div className="flex items-start space-x-3">
                  <span className="text-2xl">{card.icon}</span>
                  <div>
                    <h3 className="font-semibold text-gray-800">{card.title}</h3>
                    <p className="text-sm text-gray-600">{card.description}</p>
                  </div>
                </div>
                <span className={`transform transition-transform duration-200 
                  ${expandedCard === card.id ? 'rotate-180' : ''}`}>
                  ▼
                </span>
              </div>
            </button>
            
            {expandedCard === card.id && (
              <div 
                className="mt-2 p-3 bg-white rounded-lg border border-purple-200 shadow-lg"
              >
                <div 
                  className="text-gray-700 space-y-2 cursor-pointer hover:text-purple-600"
                  onClick={() => {
                    const newText = dreamDescription 
                      ? `${dreamDescription}\n\n${card.examples[0]}`
                      : card.examples[0];
                    
                    setDreamDescription(newText);
                    document.getElementById('dream-textarea')?.scrollIntoView({ 
                      behavior: 'smooth',
                      block: 'center'
                    });
                  }}
                >
                  <div className="space-y-2">
                    <p className="text-sm font-medium border-b border-purple-100 pb-2">
                      {card.examples[0].split('.')[0]}.
                    </p>
                    
                    <div className="grid grid-cols-2 gap-2 text-xs">
                      <div className="space-y-1.5">
                        <p><span className="text-purple-500">•</span> <strong>Space:</strong> {card.examples[0].match(/space: ([^.]+)/i)?.[1]}</p>
                        <p><span className="text-purple-500">•</span> <strong>Vibe:</strong> {card.examples[0].match(/vibe: ([^.]+)/i)?.[1]}</p>
                        <p><span className="text-purple-500">•</span> <strong>Bar:</strong> {card.examples[0].match(/bar: ([^.]+)/i)?.[1]}</p>
                      </div>
                      <div className="space-y-1.5">
                        <p><span className="text-purple-500">•</span> <strong>Food:</strong> {card.examples[0].match(/food: ([^.]+)/i)?.[1]}</p>
                        <p><span className="text-purple-500">•</span> <strong>Extras:</strong> {card.examples[0].match(/extras: ([^.]+)/i)?.[1]}</p>
                        <p><span className="text-purple-500">•</span> <strong>Budget:</strong> {card.examples[0].match(/budget: ([^.]+)/i)?.[1]}</p>
                      </div>
                    </div>
                    
                    <p className="text-xs text-purple-600 italic mt-2 pt-2 border-t border-purple-100">
                      👆 Tap anywhere to copy
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  // Main component render with layout and animations
  return (
    <MainLayout
      progressTracker={<ProgressTracker currentStep={3} />}
    >
      <motion.div 
        className="max-w-4xl mx-auto bg-white rounded-3xl shadow-xl overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="bg-purple-600 text-white py-2 px-4 rounded-t-xl">
          <h1 className="text-lg font-semibold flex items-center gap-2">
            <span className="text-base">✨</span> 
            Describe Your Dream Event
          </h1>
        </div>

        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="mb-2 text-left text-gray-600 text-sm">
            Share your event details—polished or not, we’ve got you covered. Talk to text, type, or paste from your notes.
            </div>
            
            <textarea
              id="dream-textarea"
              value={dreamDescription}
              onChange={handleDescriptionChange}
              placeholder="Example: Hosting a party for my birthday in Chelsea, 8pm-12am, for late 20s/30s. Classy vibe with moody lighting. I’ll handle decor and music. Venue to provide apps, grazing boards, wine, beer, and a signature cocktail. Budget: $5-6K. Dress code: upscale."
              className="w-full p-3 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
              rows="6"
              required
            />
            
            {visibleSuggestions.length > 0 && (
              <div className="mt-2 space-y-1 relative">
                <button
                  onClick={handleDismissSuggestions}
                  className="absolute -top-2 -right-2 text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-purple-100 transition-colors"
                  aria-label="Dismiss all suggestions"
                >
                  ×
                </button>
                {visibleSuggestions.map((suggestion, index) => (
                  <div 
                    key={index} 
                    className="text-sm text-purple-600 bg-purple-50 p-2 rounded-lg animate-fade-in text-left"
                  >
                    💡 {suggestion}
                  </div>
                ))}
              </div>
            )}

            <div className="mt-2">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className={`h-2.5 rounded-full transition-all duration-300 ${
                    dreamDescription.length === 0 ? 'bg-gray-300 w-0' :
                    dreamDescription.length < 100 ? 'bg-red-400 w-1/4' :
                    dreamDescription.length < 250 ? 'bg-yellow-400 w-2/4' :
                    'bg-green-400 w-full'
                  }`}
                ></div>
              </div>
              {dreamDescription.length > 0 && (
                <p className="text-xs text-gray-500 mt-1">
                  {dreamDescription.length < 100 ? 'That\'s like bringing one balloon to a party... Let\'s hear more!' :
                   dreamDescription.length < 250 ? 'Now we\'re cooking! But don\'t stop there, this party isn\'t writing itself' :
                   'Absolutely nailed it! You\'re basically a professional event planner now'}
                </p>
              )}
            </div>

            <div className="relative mt-3">
              <button 
                type="submit" 
                className="w-full bg-gradient-to-r from-green-400 to-green-600 text-white py-2 px-6 rounded-lg hover:from-green-500 hover:to-green-700 transition-all focus:outline-none focus:ring-2 focus:ring-green-400 flex items-center justify-center space-x-2 font-medium text-base shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 duration-200"
              >
                <Sparkles className="w-4 h-4" />
                <span>Transform Dream into Reality</span>
                <Rocket className="w-4 h-4" />
              </button>
            </div>
          </form>
        </div>
      </motion.div>

      <div className="max-w-4xl mx-auto mt-4">
        <div className="rounded-2xl p-4 sm:p-6">
          <ExampleCards />
        </div>
      </div>
    </MainLayout>
  );
}
