import { useLocation } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useEffect } from 'react';

// MainLayout: Core layout component that provides consistent structure across pages
// Handles progress tracking and back navigation between form steps
export default function MainLayout({ progressTracker, children }) {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Map routes to their back navigation destinations and labels
  // This ensures consistent navigation flow through the form process
  const backRoutes = {
    '/details': { to: '/', label: 'Back to Occasion' },
    '/dream': { to: '/details', label: 'Back to Details' },
    '/inspiration': { to: '/dream', label: 'Back to Dream' },
    '/contact': { to: '/inspiration', label: 'Back to Inspiration' }
  };

  // Conditionally render back button based on current route
  const backButton = backRoutes[location.pathname] ? (
    <BackButton 
      to={backRoutes[location.pathname].to} 
      label={backRoutes[location.pathname].label} 
    />
  ) : null;

  return (
    // Main layout structure with responsive container and spacing
    // Uses flex column to ensure footer stays at bottom
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="max-w-[90rem] mx-auto w-full flex-grow -mt-4">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* Order controls visual hierarchy - back button above progress */}
          <div className="flex flex-col space-y-0">
            <div className="order-1">
              {backButton}
            </div>
            <div className="order-2 w-[80%] mx-auto">
              {progressTracker}
            </div>
          </div>

          <div className="max-w-3xl mx-auto mb-16 mt-4">
            {children}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}