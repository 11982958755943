import { Navigate } from 'react-router-dom';
import logger from '../utils/logger';

/**
 * RouteGuard Component
 * Protects routes from unauthorized access
 * Features:
 * - Checks localStorage for required data
 * - Redirects to home if requirements not met
 * - Prevents access to protected routes without prerequisites
 * - Simple implementation with children prop pattern
 */
export default function RouteGuard({ children, requiredData }) {
  const eventDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
  
  if (requiredData === '/details' && !eventDetails?.setScene?.occasion) {
    logger.warning('Route guard redirect', { 
      from: window.location.pathname,
      reason: 'Missing occasion data'
    });
    return <Navigate to="/" />;
  }
  
  return children;
}