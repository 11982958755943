/**
 * Navbar Component
 * 
 * Features:
 * - Responsive navigation with mobile menu
 * - Sticky positioning with backdrop blur
 * - Brand logo with gradient text
 * - Desktop and mobile navigation links
 * - Clears localStorage on logo click for event flow reset
 * 
 * Layout:
 * - Desktop: Logo on left, navigation links on right
 * - Mobile: Logo on left, hamburger menu on right with expandable panel
 * 
 * Functionality:
 * - Tracks mobile menu open/close state
 * - Handles logo click to reset event planning flow
 * - Closes mobile menu when link is clicked
 * 
 * Styling:
 * - Semi-transparent white background with blur
 * - Purple gradient text for brand
 * - Hover and focus states for interactive elements
 * - Smooth transitions for menu interactions
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Check, Users, Wand2, Share, Mail, Calculator } from 'lucide-react';

const navigationItems = [
  { name: 'Home', href: '/' },
  { name: 'Mission', href: '/mission' },
  { name: 'Calculator', href: '/calculator' },
  // ... any other existing nav items ...
];

export function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogoClick = () => {
    localStorage.removeItem('eventDetails');
    localStorage.removeItem('currentStep');
  };

  return (
    <nav className="sticky top-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-100">
      <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex pl-0">
            <Link to="/" className="flex items-center" onClick={handleLogoClick}>
              <span className="text-xl font-bold bg-gradient-to-r from-violet-600 to-indigo-600 text-transparent bg-clip-text">
                LetsHost
              </span>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6">
            <Link
              to="/mission"
              className="text-gray-600 hover:text-gray-900"
            >
              Mission
            </Link>
            {/* Temporarily hidden calculator link
            <Link
              to="/calculator"
              className="text-gray-600 hover:text-gray-900"
            >
              Calculator
            </Link>
            */}
          </div>

          {/* Mobile Menu Button - Now with explicit styling */}
          <div className="flex md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu Panel */}
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white border-t border-gray-200">
              <Link
                to="/mission"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Mission
              </Link>
              {/* Temporarily hidden calculator link
              <Link
                to="/calculator"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Calculator
              </Link>
              */}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}