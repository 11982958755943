import React from 'react';
import { PartyPopper, Users, Sparkles, Upload, Mail } from 'lucide-react';
import logger from '../utils/logger';

/**
 * ProgressTracker Component
 * Visual indicator of user's progress through the form flow
 * Features:
 * - Different displays for mobile and desktop
 * - Animated progress bar
 * - Step completion indicators
 * - Current step highlighting
 * - Percentage-based progress calculation
 */
export default function ProgressTracker({ currentStep }) {
  if (!currentStep) {
    logger.error('Missing currentStep prop');
    return null;
  }

  // Define steps with icons instead of numbers
  const steps = [
    { number: 1, label: 'Occasion', icon: PartyPopper },
    { number: 2, label: 'Event Details', icon: Users },
    { number: 3, label: 'Dream Description', icon: Sparkles },
    { number: 4, label: 'Inspiration', icon: Upload },
    { number: 5, label: 'Contact', icon: Mail }
  ];

  return (
    <div className="max-w-2xl mx-auto -mt-1">
      {/* Desktop view */}
      <div className="hidden sm:block">
        <div className="flex justify-between mb-2">
          {steps.map((step) => {
            const Icon = step.icon;
            return (
              <div key={step.number} className="flex flex-col items-center relative">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center mb-1 ${
                  step.number <= currentStep ? 'bg-purple-600 text-white' : 'bg-gray-200'
                }`}>
                  {step.number < currentStep ? (
                    '✓'
                  ) : (
                    <Icon className="w-4 h-4" />
                  )}
                </div>
                <span className="text-xs font-medium">{step.label}</span>
              </div>
            );
          })}
        </div>
      </div>

      {/* Mobile view */}
      <div className="sm:hidden">
        <div className="grid grid-cols-5 gap-x-4 mb-1">
          {steps.map((step) => {
            const Icon = step.icon;
            return (
              <div key={step.number} className="flex flex-col items-center">
                <div className={`w-7 h-7 rounded-full flex items-center justify-center ${
                  step.number <= currentStep ? 'bg-purple-600 text-white' : 'bg-gray-200'
                }`}>
                  {step.number < currentStep ? (
                    <span className="text-xs">✓</span>
                  ) : (
                    <Icon className="w-3.5 h-3.5" />
                  )}
                </div>
                {step.number === currentStep && (
                  <span className="text-xs font-medium text-gray-900 mt-1 text-center w-full">
                    {step.label}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Progress bar */}
      <div className="w-full bg-gray-200 rounded-full h-1.5 sm:h-2">
        <div 
          className="h-1.5 sm:h-2 bg-purple-600 rounded-full transition-all duration-500"
          style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
        />
      </div>
    </div>
  );
}