/**
 * Important Notes:
 * - Final step (5/5) in event planning flow
 * - Requires all previous steps' data from localStorage
 * - Submits to Airtable and redirects to success page
 * 
 * Key Features:
 * - Real-time phone number formatting
 * - Form validation with error toasts
 * - Prevents double submissions
 * - Merges contact info with existing event details
 */

import React, { useState, useEffect } from 'react' // Core React imports
import { useNavigate } from 'react-router-dom' // For navigation after form submission
import { motion } from 'framer-motion' // For animation effects
import { Mail, Phone, User, User2 } from 'lucide-react' // Icons for form inputs
import airtableService from '../utils/airtable'; // Service for submitting to Airtable
import { toast } from 'react-hot-toast'; // For showing toast notifications
import ProgressTracker from '../components/ProgressTracker'; // Shows progress in form flow
import MainLayout from '../layouts/MainLayout'; // Main page layout wrapper
import logger from '../utils/logger'; // Logging utility

// Contact form: Final step in event planning flow
// Collects user contact info for venue proposals
// Uses icon-enhanced inputs for better visual hierarchy
export default function Contact() {
  // Form state management
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); // Prevents double submissions

  // Log component mount for debugging
  useEffect(() => {
    logger.group('Contact Form');
    logger.info('Environment initialized');
    logger.groupEnd(); 
  }, []);

  // Formats phone number as user types (XXX-XXX-XXXX)
  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  // Handles changes to form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'phone' ? formatPhoneNumber(value) : value
    }));
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isSubmitting) return; // Prevent multiple submissions
    
    // Validate all fields are filled and phone is 10 digits
    const validationError = Object.entries(formData).find(([key, value]) => {
      if (!value.trim()) return `${key} is required`;
      if (key === 'phone' && value.replace(/\D/g, '').length !== 10) {
        return 'Valid phone number required';
      }
      return null;
    });

    if (validationError) {
      toast.error(validationError);
      return;
    }
    
    setIsSubmitting(true);
    const loadingToast = toast.loading('Submitting your request...');
    
    try {
      // Merge new contact info with existing event details
      const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
      const updatedDetails = {
        ...existingDetails,
        contact: {
          ...formData,
          phone: formData.phone.replace(/\D/g, '') // Store phone without formatting
        }
      };
      
      // Submit to Airtable and update localStorage
      await airtableService.submitEvent(updatedDetails);
      localStorage.setItem('eventDetails', JSON.stringify(updatedDetails));
      toast.dismiss(loadingToast);
      navigate('/success'); // Redirect to success page
    } catch (error) {
      logger.error('Submission failed', error);
      toast.error('Failed to submit form. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <MainLayout
      progressTracker={<ProgressTracker currentStep={5} />}
    >
      {/* Main form container with animation */}
      <motion.div
        className="w-full max-w-md mx-auto mt-6 rounded-2xl shadow-lg bg-gradient-to-r from-purple-400 to-pink-500 p-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="bg-white rounded-xl p-4 space-y-2">
          {/* Form header */}
          <div className="space-y-1">
            <h2 className="text-lg font-semibold text-gray-800">Almost there! 🎉</h2>
            <p className="text-xs text-gray-600">
              We'll send venue options to your email and only contact you by phone for urgent updates.
            </p>
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-2">
            {/* Name fields grid */}
            <div className="grid grid-cols-2 gap-2">
              <div className="relative">
                <User className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className="w-full pl-8 pr-2 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-purple-500 focus:border-transparent"
                  required
                />
              </div>
              <div className="relative">
                <User2 className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className="w-full pl-8 pr-2 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-purple-500 focus:border-transparent"
                  required
                />
              </div>
            </div>

            {/* Email field */}
            <div className="relative">
              <Mail className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                className="w-full pl-8 pr-2 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-purple-500 focus:border-transparent"
                required
              />
            </div>

            {/* Phone field */}
            <div className="relative">
              <Phone className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <input
                id="phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="123-456-7890"
                className="w-full pl-8 pr-2 py-1.5 text-sm border border-gray-300 rounded-lg focus:ring-1 focus:ring-purple-500 focus:border-transparent"
                required
              />
            </div>

            {/* Submit button with loading state */}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full ${
                isSubmitting 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600'
              } text-white py-1.5 rounded-lg transition-colors text-sm font-medium`}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      </motion.div>
    </MainLayout>
  );
}
