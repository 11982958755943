const logger = {
  styles: {
    info: 'color: #2563eb',
    success: 'color: #059669',
    warning: 'color: #d97706',
    error: 'color: #dc2626'
  },

  icons: {
    info: 'ℹ️',
    success: '✅',
    warning: '⚠️',
    error: '❌'
  },

  logHistory: new Set(),

  enabled: process.env.NODE_ENV !== 'production' || process.env.REACT_APP_DEBUG === 'true',

  formatError(error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      // Add any additional error properties
      ...(error.code && { code: error.code }),
      ...(error.response && { response: error.response }),
      ...(error.config && { config: error.config })
    };
  },

  createLogKey(type, message, data) {
    return `${type}-${message}-${JSON.stringify(data)}`;
  },

  log(type, message, data = null) {
    if (!this.enabled) return;
    
    // Format error objects if present
    if (data instanceof Error) {
      data = this.formatError(data);
    } else if (data && data.error instanceof Error) {
      data.error = this.formatError(data.error);
    }
    
    const logKey = this.createLogKey(type, message, data);
    if (this.logHistory.has(logKey)) return;
    
    const logFn = type === 'error' ? console.error : console.log;
    const output = `%c${this.icons[type]} ${message}${data ? ':' : ''}`;
    
    // Add timestamp to logs
    const timestamp = new Date().toISOString();
    const enrichedData = data ? { timestamp, ...data } : { timestamp };
    
    data ? logFn(output, this.styles[type], enrichedData) : logFn(output, this.styles[type]);
    
    this.logHistory.add(logKey);
    setTimeout(() => this.logHistory.delete(logKey), 1000);
  },

  group(name, type = 'info') {
    if (!this.enabled) return;
    const groupKey = this.createLogKey('group', name, type);
    if (this.logHistory.has(groupKey)) return;
    
    const timestamp = new Date().toISOString();
    console.group(`%c${this.icons[type]} ${name} (${timestamp})`, this.styles[type]);
    this.logHistory.add(groupKey);
    setTimeout(() => this.logHistory.delete(groupKey), 1000);
  },

  groupEnd() {
    if (!this.enabled) return;
    console.groupEnd();
  },

  info: function(m, d) { this.log('info', m, d) },
  success: function(m, d) { this.log('success', m, d) },
  warning: function(m, d) { this.log('warning', m, d) },
  error: function(m, d) { this.log('error', m, d) }
};

export default logger;