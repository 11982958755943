/**
 * EventDetails Component
 * Collects specific details about the user's event
 * Features:
 * - Form validation with error handling
 * - Auto-save to localStorage
 * - Smart time handling (defaults to PM)
 * - Dynamic activity options based on occasion
 * - Responsive form layout
 * - Form state persistence
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MapPin, Users, DollarSign, Calendar, Clock, Timer, Wand2, Utensils, Wine, InfoIcon } from 'lucide-react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProgressTracker from '../components/ProgressTracker';
import MainLayout from '../layouts/MainLayout';
import logger from '../utils/logger';
import Tooltip from '@mui/material/Tooltip';

// Base styles for form controls - ensures consistent appearance across all inputs
const formControlStyles = {
  width: '100%',
  mb: { xs: 1, sm: 1.5 },
  backgroundColor: 'white',
  borderRadius: '0.5rem',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    border: '1px solid rgb(229, 231, 235)',
    '&:hover': {
      borderColor: 'rgb(209, 213, 219)',
    },
    '& .MuiSelect-select': {
      lineHeight: '1.2',
      padding: '12px 14px',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      height: '1.4375em',
    },
    '& fieldset': {
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',
      }
    },
  }
};

export default function EventDetails() {
  const navigate = useNavigate();
  
  // Tracks if user has attempted to submit the form - used for error display timing
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  
  // Stores validation error messages for each form field
  const [errors, setErrors] = useState({
    activity: '',
    guestCount: '',
    budget: '',
    date: '',
    startTime: '',
    duration: '',
    foodOption: '',
    drinkOption: ''
  });

  // Initialize form state with saved data from localStorage or defaults
  const [eventDetails, setEventDetails] = useState(() => {
    const saved = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    return {
      occasion: saved.setScene?.occasion || '',
      activity: saved.setScene?.activity || '',
      guestCount: saved.setScene?.guestCount || '',
      budget: saved.setScene?.budget || '',
      date: saved.setScene?.date ? dayjs(saved.setScene.date) : null,
      startTime: saved.setScene?.startTime ? dayjs(`2024-01-01T${saved.setScene.startTime}`) : null,
      duration: saved.setScene?.duration || '',
      foodAndBev: {
        needs: saved.setScene?.foodAndBev?.needs || [],
        foodOption: saved.setScene?.foodAndBev?.foodOption || '',
        drinkOption: saved.setScene?.foodAndBev?.drinkOption || ''
      }
    };
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    const formattedDetails = {
      ...eventDetails,
      date: eventDetails.date?.format('YYYY-MM-DD'),
      startTime: eventDetails.startTime?.format('HH:mm')
    };

    const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    localStorage.setItem('eventDetails', JSON.stringify({
      ...existingDetails,
      setScene: {
        ...existingDetails.setScene,
        ...formattedDetails
      }
    }));
  }, [eventDetails]);

  // Handles input changes and automatically defaults time to PM for common event times
  const handleInputChange = (field, value) => {
    if (field === 'startTime' && value) {
      // Convert to PM if hour is between 1-7 (assumes events typically happen in evening)
      if (!value.format('A')) {
        const hour = value.hour();
        value = value.hour(hour + 12);
      }
    }
    
    setEventDetails(prev => ({
      ...prev,
      [field]: value
    }));
    
    // Only show validation errors after first submission attempt
    if (hasAttemptedSubmit) {
      if (value) {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        });
      } else {
        setErrors(prev => ({
          ...prev,
          [field]: `Please select ${field}`
        }));
      }
    }
  };

  // Checks if all required fields are filled
  const allFieldsFilled = () => {
    return eventDetails.activity && 
           eventDetails.guestCount && 
           eventDetails.budget && 
           eventDetails.date?.isValid() && 
           eventDetails.startTime?.isValid() && 
           eventDetails.duration &&
           eventDetails.foodOption &&
           eventDetails.drinkOption;
  };

  // Validates form and returns first error found or null if valid
  const validateForm = () => {
    const newErrors = {};
    
    // Check each required field
    if (!eventDetails.activity) newErrors.activity = 'Please select an activity';
    if (!eventDetails.guestCount) newErrors.guestCount = 'Please select guest count';
    if (!eventDetails.budget) newErrors.budget = 'Please select budget';
    if (!eventDetails.date?.isValid()) newErrors.date = 'Please select a valid date';
    if (!eventDetails.startTime?.isValid()) newErrors.startTime = 'Please select a valid time';
    if (!eventDetails.duration) newErrors.duration = 'Please select duration';

    setErrors(newErrors);
    setHasAttemptedSubmit(true);

    return Object.values(newErrors)[0] || null;
  };

  // Handles form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      logger.error('Form validation failed', validationError);
      return;
    }

    // Format dates for storage
    const formattedDetails = {
      ...eventDetails,
      date: eventDetails.date?.format('YYYY-MM-DD'),
      startTime: eventDetails.startTime?.format('HH:mm')
    };

    // Save and navigate to next step
    logger.info('Saving event details', formattedDetails);
    localStorage.setItem('eventDetails', JSON.stringify({
      ...JSON.parse(localStorage.getItem('eventDetails') || '{}'),
      setScene: formattedDetails
    }));

    navigate('/dream');
  };

  // Returns different activity options based on the selected occasion type
  const getActivitiesByOccasion = () => {
    const savedOccasion = JSON.parse(localStorage.getItem('eventDetails') || '{}')?.setScene?.occasion;
    
    switch (savedOccasion) {
      case 'birthday':
        return [
          'Night Out', 'Cocktail Party', 'Dinner', 'Day Party',
          'Bottomless Brunch', 'Happy Hour', 'Custom Event'
        ];
      case 'business':
        return [
          'Happy Hour', 'Dinner', 'Meeting', 'Networking',
          'Conference', 'Holiday Party', 'Custom Event'
        ];
      case 'everything-else':
        return [
          'Dinner', 'Cocktail Party', 'Happy Hour', 'Dancing',
          'Day Party', 'Networking', 'Custom Event'
        ];
      default:
        return [
          'Happy Hour', 'Dinner', 'Party', 'Meeting',
          'Networking', 'Custom Event'
        ];
    }
  };

  // Get dynamic activities based on occasion
  const activities = getActivitiesByOccasion();

  // Predefined guest count ranges
  const guestCounts = [
    '1-10', '11-20', '21-30', '31-40', '41-50',
    '51-75', '76-100', '101-150', '151-200', '200+'
  ];

  // Predefined budget ranges with consistent increments
  const budgetRanges = [
    'Under $500',
    '$500-$1,000',
    '$1,000-$2,000',
    '$2,000-$3,500',
    '$3,500-$5,000',
    '$5,000-$7,500',
    '$7,500-$10,000',
    '$10,000-$15,000',
    '$15,000-$25,000',
    '$25,000+'
  ];

  // Standard event duration options
  const durations = [
    '2 hours', '3 hours', '4 hours', '5 hours', '6+ hours'
  ];

  // Check if viewport is mobile for responsive design
  const isMobile = useMediaQuery('(max-width:640px)');

  // Animation variants for form elements
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1 // Stagger children animations
      }
    }
  };

  // Animation variants for individual form items
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  // Save form state before page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      const formattedDetails = {
        ...eventDetails,
        date: eventDetails.date?.format('YYYY-MM-DD'),
        startTime: eventDetails.startTime?.format('HH:mm')
      };

      localStorage.setItem('eventDetails', JSON.stringify({
        ...JSON.parse(localStorage.getItem('eventDetails') || '{}'),
        setScene: formattedDetails
      }));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [eventDetails]);

  // Resets form while preserving occasion selection
  const handleClear = () => {
    // Get existing data to preserve occasion
    const existingData = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    const occasion = existingData.setScene?.occasion || '';

    // Reset form state but keep occasion
    setEventDetails({
      occasion: occasion,
      activity: '',
      guestCount: '',
      budget: '',
      date: null,
      startTime: null,
      duration: '',
      foodAndBev: {
        needs: [],
        foodOption: '',
        drinkOption: ''
      }
    });
    
    // Clear errors
    setErrors({});
    setHasAttemptedSubmit(false);
    
    // Update localStorage - preserve occasion but clear other fields
    localStorage.setItem('eventDetails', JSON.stringify({
      ...existingData,
      setScene: {
        occasion: occasion
      }
    }));
  };

  // Add this function near the top of your component
  const getTitleByOccasion = () => {
    const savedOccasion = JSON.parse(localStorage.getItem('eventDetails') || '{}')?.setScene?.occasion;
    
    switch (savedOccasion) {
      case 'birthday':
        return 'birthday';
      case 'business':
        return 'work event';
      case 'special occasion':
        return 'special occasion';
      case 'other':
        return 'event';
      default:
        return 'event';
    }
  };

  const getFieldsStatus = () => {
    const totalFields = 6; // total number of required fields
    const filledFields = [
      eventDetails.activity,
      eventDetails.guestCount,
      eventDetails.budget,
      eventDetails.date?.isValid(),
      eventDetails.startTime?.isValid(),
      eventDetails.duration
    ].filter(Boolean).length;

    return {
      filled: filledFields,
      remaining: totalFields - filledFields
    };
  };

  // eslint-disable-next-line no-unused-vars
  const foodOptions = [
    { value: 'passed-hors', label: "Passed Hors d'oeuvre: Small bites served by staff" },
    { value: 'grazing-table', label: 'Grazing Table/Light Bites: Self-serve snacks or platters' },
    { value: 'full-dinner', label: 'Full Dinner: A full meal, plated or buffet-style' },
    { value: 'no-food', label: 'No Food: No food needed' }
  ];
  
  // eslint-disable-next-line
  const drinkOptions = [
    { value: 'open-bar', label: 'Open Bar: Unlimited drinks for a set price' },
    { value: 'bar-tab', label: 'Bar Tab: Drinks capped at a set budget' },
    { value: 'cash-bar', label: 'Cash Bar: Guests pay for their own drinks' },
    { value: 'passed-cocktails', label: 'Passed Cocktails: Pre-made drinks served by staff' }
  ];

  const handleFBChange = (field, value) => {
    setEventDetails(prev => ({
      ...prev,
      foodAndBev: {
        ...prev.foodAndBev,
        [field]: value
      }
    }));
    
    // Only update errors if there's been a submission attempt
    if (hasAttemptedSubmit) {
      if (value) {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        });
      } else {
        setErrors(prev => ({
          ...prev,
          [field]: `Please select ${field}`
        }));
      }
    }
  };

  return (
    <MainLayout
      progressTracker={<ProgressTracker currentStep={2} />}
    >
      <motion.div 
        className="max-w-4xl mx-auto mt-4 bg-gray-50 rounded-3xl shadow-xl overflow-hidden p-6 sm:p-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="mb-1 sm:mb-3">
          <h1 className="text-2xl sm:text-3xl font-bold">
            Tell us about your{' '}
            <span className="bg-gradient-to-r from-violet-500 via-fuchsia-500 to-pink-500 bg-clip-text text-transparent">
              {getTitleByOccasion()}
            </span>
          </h1>
        </div>

        <motion.div
          className="w-full pt-1 sm:pt-2"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.form 
            onSubmit={handleSubmit}
            className="space-y-2 sm:space-y-3"
          >
            <motion.div 
              className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mb-4 sm:mb-6"
              variants={containerVariants}
            >
              <motion.div variants={itemVariants}>
                <FormControl 
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlStyles,
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    }
                  }}
                  error={hasAttemptedSubmit && !!errors.activity}
                >
                  <InputLabel>
                    <span className="flex items-center">
                      <MapPin className="w-4 h-4 mr-2" />
                      What are we doing?
                    </span>
                  </InputLabel>
                  <Select
                    value={eventDetails.activity}
                    label={
                      <span className="flex items-center">
                        <MapPin className="w-4 h-4 mr-2" />
                        What are we doing?
                      </span>
                    }
                    onChange={(e) => handleInputChange('activity', e.target.value)}
                  >
                    {activities.map(activity => (
                      <MenuItem key={activity} value={activity}>{activity}</MenuItem>
                    ))}
                  </Select>
                  {hasAttemptedSubmit && errors.activity && (
                    <p className="mt-1 text-xs text-red-500">{errors.activity}</p>
                  )}
                </FormControl>
              </motion.div>

              <motion.div variants={itemVariants}>
                <FormControl 
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlStyles,
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    }
                  }}
                  error={hasAttemptedSubmit && !!errors.guestCount}
                >
                  <InputLabel>
                    <span className="flex items-center">
                      <Users className="w-4 h-4 mr-2" />
                      Guest Count
                    </span>
                  </InputLabel>
                  <Select
                    value={eventDetails.guestCount}
                    label={
                      <span className="flex items-center">
                        <Users className="w-4 h-4 mr-2" />
                        Guest Count
                      </span>
                    }
                    onChange={(e) => handleInputChange('guestCount', e.target.value)}
                  >
                    {guestCounts.map(count => (
                      <MenuItem key={count} value={count}>{count}</MenuItem>
                    ))}
                  </Select>
                  {hasAttemptedSubmit && errors.guestCount && (
                    <p className="mt-1 text-xs text-red-500">{errors.guestCount}</p>
                  )}
                </FormControl>
              </motion.div>

              <motion.div variants={itemVariants}>
                <FormControl 
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlStyles,
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    }
                  }}
                  error={hasAttemptedSubmit && !!errors.budget}
                >
                  <InputLabel>
                    <span className="flex items-center">
                      <DollarSign className="w-4 h-4 mr-2" />
                      Total Event Budget
                      <Tooltip 
                        title="Includes all costs: venue rental, bar minimum, food, drinks, and service fees/gratuity"
                        arrow
                        placement="top"
                        disableFocusListener
                        disableTouchListener
                        enterTouchDelay={0}
                        leaveTouchDelay={0}
                      >
                        <InfoIcon className="w-4 h-4 ml-1 text-gray-400 cursor-help" />
                      </Tooltip>
                    </span>
                  </InputLabel>
                  <Tooltip 
                    title="Includes all costs: venue rental, bar minimum, food, drinks, and service fees/gratuity"
                    arrow
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    enterTouchDelay={0}
                    leaveTouchDelay={0}
                  >
                    <Select
                      value={eventDetails.budget}
                      label={
                        <span className="flex items-center">
                          <DollarSign className="w-4 h-4 mr-2" />
                          Total Event Budget
                          <InfoIcon className="w-4 h-4 ml-1 text-gray-400" />
                        </span>
                      }
                      onChange={(e) => handleInputChange('budget', e.target.value)}
                    >
                      {budgetRanges.map(range => (
                        <MenuItem key={range} value={range}>{range}</MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                  {hasAttemptedSubmit && errors.budget && (
                    <p className="mt-1 text-xs text-red-500">{errors.budget}</p>
                  )}
                </FormControl>
              </motion.div>

              <motion.div variants={itemVariants}>
                <FormControl 
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlStyles,
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    }
                  }}
                  error={hasAttemptedSubmit && !!errors.duration}
                >
                  <InputLabel>
                    <span className="flex items-center">
                      <Timer className="w-4 h-4 mr-2" />
                      Duration
                    </span>
                  </InputLabel>
                  <Select
                    value={eventDetails.duration}
                    label={
                      <span className="flex items-center">
                        <Timer className="w-4 h-4 mr-2" />
                        Duration
                      </span>
                    }
                    onChange={(e) => handleInputChange('duration', e.target.value)}
                  >
                    {durations.map(duration => (
                      <MenuItem key={duration} value={duration}>{duration}</MenuItem>
                    ))}
                  </Select>
                  {hasAttemptedSubmit && errors.duration && (
                    <p className="mt-1 text-xs text-red-500">{errors.duration}</p>
                  )}
                </FormControl>
              </motion.div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-2" />
                      Date
                    </span>
                  }
                  value={eventDetails.date}
                  onChange={(newValue) => handleInputChange('date', newValue)}
                  minDate={dayjs()}
                  slotProps={{
                    textField: {
                      size: isMobile ? "small" : "medium",
                      error: hasAttemptedSubmit && !!errors.date,
                      fullWidth: true,
                      sx: {
                        ...formControlStyles,
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                        },
                        '& .MuiOutlinedInput-root': {
                          fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' },
                          '& .MuiInputAdornment-root': {
                            '& svg': {
                              width: '28px',
                              height: '28px',
                              cursor: 'pointer',
                              color: 'rgb(139, 92, 246)',
                              filter: 'drop-shadow(0 2px 2px rgb(0 0 0 / 0.1))',
                              animation: 'pulse 2s infinite',
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                color: 'rgb(109, 40, 217)',
                                transform: 'scale(1.1)',
                                filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.15))'
                              }
                            },
                            '&::after': {
                              content: '"Click to select"',
                              position: 'absolute',
                              right: '40px',
                              fontSize: '0.75rem',
                              color: 'rgb(107, 114, 128)',
                              pointerEvents: 'none'
                            }
                          }
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label={
                    <span className="flex items-center">
                      <Clock className="w-4 h-4 mr-2" />
                      Time
                    </span>
                  }
                  value={eventDetails.startTime}
                  onChange={(newValue) => handleInputChange('startTime', newValue)}
                  minutesStep={30}
                  views={['hours', 'minutes']}
                  shouldDisableTime={(value, view) => {
                    if (view === 'minutes') {
                      const minutes = value.minute();
                      return minutes !== 0 && minutes !== 30;
                    }
                    return false;
                  }}
                  skipDisabled
                  ampm={true}
                  ampmInClock={true}
                  slotProps={{
                    textField: {
                      size: isMobile ? "small" : "medium",
                      error: hasAttemptedSubmit && !!errors.startTime,
                      fullWidth: true,
                      sx: {
                        ...formControlStyles,
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                        },
                        '& .MuiOutlinedInput-root': {
                          fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' },
                          '& .MuiInputAdornment-root': {
                            '& svg': {
                              width: '28px',
                              height: '28px',
                              cursor: 'pointer',
                              color: 'rgb(139, 92, 246)',
                              filter: 'drop-shadow(0 2px 2px rgb(0 0 0 / 0.1))',
                              animation: 'pulse 2s infinite',
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                color: 'rgb(109, 40, 217)',
                                transform: 'scale(1.1)',
                                filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.15))'
                              }
                            },
                            '&::after': {
                              content: '"Click to select"',
                              position: 'absolute',
                              right: '40px',
                              fontSize: '0.75rem',
                              color: 'rgb(107, 114, 128)',
                              pointerEvents: 'none'
                            }
                          }
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>

              <motion.div variants={itemVariants}>
                <FormControl 
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    ...formControlStyles,
                    '& .MuiInputLabel-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                    }
                  }}
                >
                  <InputLabel>
                    <span className="flex items-center">
                      <Utensils className="w-4 h-4 mr-2" />
                      Food & Beverage Needs
                    </span>
                  </InputLabel>
                  <Select
                    multiple
                    value={eventDetails.foodAndBev.needs}
                    onChange={(e) => handleFBChange('needs', e.target.value)}
                    label={
                      <span className="flex items-center">
                        <Utensils className="w-4 h-4 mr-2" />
                        Food & Beverage Needs
                      </span>
                    }
                    renderValue={(selected) => selected.map(value => 
                      value === 'needsFood' ? 'Food Service' : 'Drink Service'
                    ).join(', ')}
                  >
                    <MenuItem value="needsFood">
                      <Checkbox checked={eventDetails.foodAndBev.needs.includes('needsFood')} />
                      <ListItemText primary="Food Service" />
                    </MenuItem>
                    <MenuItem value="needsDrinks">
                      <Checkbox checked={eventDetails.foodAndBev.needs.includes('needsDrinks')} />
                      <ListItemText primary="Drink Service" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </motion.div>

              {eventDetails.foodAndBev.needs.includes('needsFood') && (
                <motion.div variants={itemVariants}>
                  <FormControl 
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      ...formControlStyles,
                      '& .MuiInputLabel-root': {
                        fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                      },
                      '& .MuiOutlinedInput-root': {
                        fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                      }
                    }}
                  >
                    <InputLabel>
                      <span className="flex items-center">
                        <Utensils className="w-4 h-4 mr-2" />
                        Food Options
                      </span>
                    </InputLabel>
                    <Select
                      value={eventDetails.foodAndBev.foodOption}
                      onChange={(e) => handleFBChange('foodOption', e.target.value)}
                      label={
                        <span className="flex items-center">
                          <Utensils className="w-4 h-4 mr-2" />
                          Food Options
                        </span>
                      }
                    >
                      <MenuItem value="passed-appetizers">Passed Appetizers / Hors D'oeuvres</MenuItem>
                      <MenuItem value="grazing-table">Grazing Table / Self-Serve Light Bites</MenuItem>
                      <MenuItem value="full-dinner">Full Dinner Service</MenuItem>
                    </Select>
                  </FormControl>
                </motion.div>
              )}

              {eventDetails.foodAndBev.needs.includes('needsDrinks') && (
                <motion.div variants={itemVariants}>
                  <FormControl 
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      ...formControlStyles,
                      '& .MuiInputLabel-root': {
                        fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                      },
                      '& .MuiOutlinedInput-root': {
                        fontSize: { xs: '0.875rem', xl: '1rem', '2xl': '1.125rem' }
                      }
                    }}
                  >
                    <InputLabel>
                      <span className="flex items-center">
                        <Wine className="w-4 h-4 mr-2" />
                        Drink Options
                      </span>
                    </InputLabel>
                    <Select
                      value={eventDetails.foodAndBev.drinkOption}
                      onChange={(e) => handleFBChange('drinkOption', e.target.value)}
                      label={
                        <span className="flex items-center">
                          <Wine className="w-4 h-4 mr-2" />
                          Drink Options
                        </span>
                      }
                    >
                      <MenuItem value="bar-minimum">
                        <span className="font-bold">Bar Minimum</span>: Guests Pay & You Cover Any Balance Due 
                      </MenuItem>
                      <MenuItem value="open-bar">
                        <span className="font-bold">Open Bar</span>: Unlimited Drinks For A Set Price
                      </MenuItem>
                      <MenuItem value="passed-cocktails">
                        <span className="font-bold">Passed Cocktails</span>: Pre-Made Drinks Served By Staff For A Set Price
                      </MenuItem>
                    </Select>
                  </FormControl>
                </motion.div>
              )}
            </motion.div>

            <div className="flex flex-col gap-1 sm:gap-2 mt-2 sm:mt-4">
              <motion.button
                type="submit"
                className={`w-full py-1.5 sm:py-2.5 px-4 sm:px-6 rounded-lg transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-1 ${
                  allFieldsFilled() 
                    ? 'bg-gradient-to-r from-emerald-400 to-emerald-600' 
                    : 'bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500'
                } text-white text-base sm:text-lg`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                animate={allFieldsFilled() ? {
                  scale: [1, 1.03, 1],
                  transition: { duration: 0.3 }
                } : {}}
              >
                <span className="flex flex-col items-center justify-center">
                  <span className="flex items-center justify-center space-x-2 sm:space-x-3">
                    <Wand2 className="w-4 h-4 sm:w-5 sm:h-5" />
                    <span>Set the Scene ✨</span>
                  </span>
                  {!allFieldsFilled() && hasAttemptedSubmit && (
                    <span className="text-xs mt-1 opacity-90">
                      {getFieldsStatus().remaining} field{getFieldsStatus().remaining !== 1 ? 's' : ''} remaining
                    </span>
                  )}
                </span>
              </motion.button>

              <button
                type="button"
                onClick={handleClear}
                className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 transition-colors self-center"
              >
                Clear all fields
              </button>
            </div>
          </motion.form>
        </motion.div>
      </motion.div>
    </MainLayout>
  );
}
