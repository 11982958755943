import React, { useState, useRef, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  X, 
  Plus, 
  Search, 
  ArrowUpDown,
  MinusCircle,
  ArrowLeftCircle,
  XCircle
} from 'lucide-react';
import MainLayout from '../layouts/MainLayout';

// Initial data
const initialEvents = [
  {
    id: 1,
    name: 'Birthday Dinner Party',
    date: '2024-03-15',
    budget: 6000,
    guests: 40,
    location: 'Chelsea, Manhattan',
    status: 'Planning',
    proposalsReceived: 3,
    description: 'Upscale birthday dinner for 40 friends. Looking for a private dining room with moody lighting and great cocktails. Need space for mingling before dinner, then seated dinner service. Full bar package with wine pairings and signature cocktails. Family-style Italian menu with vegetarian options. Budget includes food, drink, and service.',
    requirements: {
      space: 'Private dining room with cocktail area',
      vibe: 'Upscale with moody lighting, exposed brick preferred',
      food: 'Family-style Italian, dietary options needed',
      bar: 'Full bar package with wine pairings',
      extras: 'Space for cake cutting, venue playlist fine',
      timing: '7:00 PM - 11:00 PM'
    },
    proposals: [
      {
        name: 'Osteria Via Roma',
        price: 5500,
        capacity: '30-50 guests',
        availability: 'Available',
        details: {
          space: 'Private dining room with wine cellar views',
          includes: 'Tables, chairs, linens, place settings',
          minimums: 'F&B minimum: $4,000',
          restrictions: 'In-house catering only'
        },
        pitch: "Our intimate private dining room is perfect for your upscale birthday dinner. We'll create a custom Italian menu with wine pairings, and our sommelier can guide your guests through a unique tasting experience."
      },
      {
        name: 'The Chelsea Kitchen',
        price: 6200,
        capacity: '35-45 guests',
        availability: 'Available',
        details: {
          space: 'Chef\'s private dining room with open kitchen',
          includes: 'Custom menu, wine service, chef interaction',
          minimums: 'F&B minimum: $3,500',
          restrictions: 'Set menu required'
        },
        pitch: "Experience an interactive dining experience in our chef's private room. Watch as your custom menu is prepared, and enjoy the intimate atmosphere perfect for conversation and celebration."
      },
      {
        name: 'Brick & Vine',
        price: 5800,
        capacity: '30-60 guests',
        availability: 'Limited',
        details: {
          space: 'Rustic-modern private dining space',
          includes: 'Full bar, AV system, custom lighting',
          minimums: 'F&B minimum: $3,000',
          restrictions: 'Outside desserts allowed with fee'
        },
        pitch: "Our private dining space offers the perfect blend of sophistication and comfort. We'll create a family-style Italian feast and can accommodate your cake presentation and music preferences."
      }
    ]
  },
  {
    id: 2,
    name: 'Company Holiday Party',
    date: '2024-12-12',
    budget: 25000,
    guests: 120,
    location: 'Tribeca, Manhattan',
    status: 'Confirmed',
    proposalsReceived: 6,
    description: 'Tech startup holiday party for 120 people. Need a cool industrial space with room for passed apps, food stations, and dancing. Looking for premium open bar with themed cocktails. Mix of high-tops and lounge seating. Photo booth area and space for DJ setup required.',
    requirements: {
      space: 'Industrial loft or converted warehouse',
      vibe: 'Modern and festive with versatile layout',
      food: 'Passed apps and chef stations, dietary options',
      bar: 'Premium open bar with signature drinks',
      extras: 'Photo booth space, DJ setup area',
      timing: '8:00 PM - 1:00 AM'
    },
    proposals: [
      {
        name: 'Tribeca View',
        price: 18000,
        capacity: '100-200 guests',
        availability: 'Available',
        details: {
          space: 'Penthouse venue with Manhattan skyline views',
          includes: 'Designer furniture, AV system, getting ready rooms',
          minimums: 'F&B minimum: $15,000 (Fri-Sat)',
          restrictions: 'Preferred vendor list for catering, in-house beverage program'
        }
      },
      {
        name: 'The Industrial',
        price: 22000,
        capacity: '150-300 guests',
        availability: 'Limited',
        details: {
          space: 'Converted warehouse with exposed beams and brick',
          includes: 'Sound system, lighting package, event manager',
          minimums: 'F&B minimum: $18,000 (Fri-Sat)',
          restrictions: 'Must use in-house catering and bar service'
        }
      },
      {
        name: 'SoHo Loft',
        price: 16500,
        capacity: '80-150 guests',
        availability: 'Available',
        details: {
          space: 'Modern loft space with floor-to-ceiling windows',
          includes: 'Furniture, basic AV, coat check',
          minimums: 'F&B minimum: $12,000 (Fri-Sat)',
          restrictions: 'Outside catering allowed with fee'
        }
      },
      {
        name: 'The Gallery',
        price: 20000,
        capacity: '100-180 guests',
        availability: 'Available',
        details: {
          space: 'Contemporary art gallery with high ceilings',
          includes: 'Tables, chairs, uplighting package',
          minimums: 'F&B minimum: $16,000 (Fri-Sat)',
          restrictions: 'Preferred vendor list, no open flames'
        }
      },
      {
        name: 'Hudson Terrace',
        price: 25000,
        capacity: '120-250 guests',
        availability: 'Limited',
        details: {
          space: 'Rooftop venue with indoor/outdoor space',
          includes: 'Full AV system, lounge furniture, heaters',
          minimums: 'F&B minimum: $20,000 (Fri-Sat)',
          restrictions: 'In-house catering and beverage only'
        }
      },
      {
        name: 'Metropolitan Club',
        price: 19500,
        capacity: '100-200 guests',
        availability: 'Available',
        details: {
          space: 'Historic venue with ballroom and cocktail space',
          includes: 'Tables, chairs, china, basic lighting',
          minimums: 'F&B minimum: $17,000 (Fri-Sat)',
          restrictions: 'Must use approved vendors list'
        }
      }
    ]
  },
  {
    id: 3,
    name: 'Bridal Shower Brunch',
    date: '2024-04-20',
    budget: 8500,
    guests: 35,
    location: 'SoHo, Manhattan',
    status: 'Planning',
    proposalsReceived: 3,
    description: 'Elegant bridal shower brunch for 35 guests. Seeking bright space with natural light and outdoor access if possible. Need seated brunch service with champagne packages. Space for gift table and activities. Looking for a venue that photographs well for social.',
    requirements: {
      space: 'Private room with natural light',
      vibe: 'Bright and airy with feminine touches',
      food: 'Upscale brunch menu with pastries',
      bar: 'Champagne packages and brunch cocktails',
      extras: 'Gift table area, space for games',
      timing: '11:00 AM - 3:00 PM'
    },
    proposals: [
      {
        name: 'The Garden Room',
        price: 7500,
        capacity: '30-40 guests',
        availability: 'Available',
        details: {
          space: 'Sun-filled conservatory with garden views',
          includes: 'Tables, chairs, china, glassware',
          minimums: 'F&B minimum: $3,500',
          restrictions: 'In-house catering preferred'
        },
        pitch: "Our bright and airy conservatory is the perfect setting for your bridal shower brunch. Floor-to-ceiling windows overlook our manicured gardens, creating stunning photo opportunities."
      },
      {
        name: 'Rosemary Bistro',
        price: 8200,
        capacity: '25-45 guests',
        availability: 'Available',
        details: {
          space: 'Private dining room with terrace access',
          includes: 'Brunch setup, champagne service, florals',
          minimums: 'F&B minimum: $4,000',
          restrictions: 'Outside décor allowed'
        },
        pitch: "Let us create a memorable bridal shower in our elegant private space. Our chef will customize a brunch menu, and we'll ensure plenty of champagne flows throughout the celebration."
      },
      {
        name: 'The Penthouse at SoHo',
        price: 9500,
        capacity: '30-50 guests',
        availability: 'Limited',
        details: {
          space: 'Luxury penthouse with skyline views',
          includes: 'Full venue access, staff, valet parking',
          minimums: 'F&B minimum: $5,000',
          restrictions: 'Approved vendors only'
        },
        pitch: "Elevate your bridal shower in our sophisticated penthouse venue. We'll create an unforgettable experience with custom food stations, premium champagne service, and stunning city views."
      }
    ]
  },
  {
    id: 4,
    name: 'Work Happy Hour',
    date: '2024-02-28',
    budget: 4500,
    guests: 45,
    location: 'Flatiron, Manhattan',
    status: 'Planning',
    proposalsReceived: 5,
    description: 'After-work networking event for 45 people. Need semi-private area with mix of seating and standing room. Looking for premium beer and wine open bar with light bites. Casual but professional atmosphere where people can easily mingle.',
    requirements: {
      space: 'Semi-private area with flexible setup',
      vibe: 'Professional but relaxed atmosphere',
      food: 'Light appetizers and bar snacks',
      bar: 'Premium beer and wine open bar',
      extras: 'High-tops and some lounge seating',
      timing: '5:30 PM - 8:30 PM'
    },
    proposals: [
      {
        name: 'The Flatiron Room',
        price: 4500,
        capacity: '30-60 guests',
        availability: 'Available',
        details: {
          space: 'Semi-private lounge area with high-tops and bar access',
          includes: 'Basic furniture, bar setup, service staff',
          minimums: 'F&B minimum: $2,500 (weekdays)',
          restrictions: 'In-house catering only, 3-hour maximum'
        }
      },
      {
        name: 'Madison Social House',
        price: 3800,
        capacity: '40-80 guests',
        availability: 'Available',
        details: {
          space: 'Modern bar with flexible semi-private area',
          includes: 'Lounge furniture, sound system, dedicated bartender',
          minimums: 'Beverage minimum: $2,000',
          restrictions: 'Must use house beverage program'
        },
        pitch: "Our versatile space can be arranged to create the perfect networking environment. High-tops and lounge seating areas encourage mingling, while our central bar ensures quick service for your group."
      },
      {
        name: 'The Loft at Park Ave',
        price: 5200,
        capacity: '35-70 guests',
        availability: 'Limited',
        details: {
          space: 'Industrial-chic loft space with city views',
          includes: 'Full bar setup, furniture, coat check',
          minimums: 'F&B minimum: $3,000',
          restrictions: 'Outside catering allowed with fee'
        },
        pitch: "Elevate your happy hour in our sophisticated loft space. Perfect for professional networking with a view. We can arrange multiple food stations and create a custom bar package for your group."
      },
      {
        name: 'The Garden Lounge',
        price: 4200,
        capacity: '30-50 guests',
        availability: 'Available',
        details: {
          space: 'Indoor/outdoor space with retractable roof',
          includes: 'Mixed seating, bar service, heaters',
          minimums: 'F&B minimum: $2,000',
          restrictions: 'Weather dependent for outdoor use'
        },
        pitch: "Our unique indoor/outdoor space offers the perfect casual atmosphere for your team to unwind. The retractable roof provides flexibility regardless of weather, and our craft beer selection is unmatched."
      },
      {
        name: 'Skyline Bar & Lounge',
        price: 4800,
        capacity: '40-60 guests',
        availability: 'Available',
        details: {
          space: 'Rooftop bar with Manhattan views',
          includes: 'Lounge furniture, bar setup, lighting',
          minimums: 'F&B minimum: $2,500',
          restrictions: 'In-house beverages only'
        },
        pitch: "Take your corporate happy hour to new heights! Our rooftop space offers breathtaking views and a sophisticated atmosphere. We'll create a custom cocktail menu for your event and can accommodate your food preferences."
      }
    ]
  }
];

export default function TestDashboard() {
  const [expandedEvent, setExpandedEvent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState('date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showProposalsModal, setShowProposalsModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [filters, setFilters] = useState({
    priceRange: [0, 50000],
    capacity: [0, 300],
    availability: []
  });
  const [showFilters, setShowFilters] = useState(false);
  const filtersRef = useRef(null);
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filtersRef.current && !filtersRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleEventDetails = (id) => {
    setExpandedEvent(expandedEvent === id ? null : id);
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedEvents = useMemo(() => {
    return initialEvents
      .filter((event) =>
        event.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortKey] > b[sortKey]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [searchTerm, sortKey, sortDirection]);

  const handleViewProposals = (event) => {
    if (!event.proposals) {
      event.proposals = []; // Initialize if undefined
    }
    // Add messages array to each proposal if it doesn't exist
    event.proposals = event.proposals.map(proposal => ({
      ...proposal,
      messages: proposal.messages || []
    }));
    setSelectedEvent(event);
    setShowProposalsModal(true);
  };

  const handleSendMessage = () => {
    if (!newMessage.trim()) return;
    
    const message = {
      from: 'user',
      timestamp: new Date().toISOString(),
      text: newMessage.trim()
    };
    
    // Update the messages array for the selected venue
    setSelectedVenue(prev => ({
      ...prev,
      messages: [...prev.messages, message]
    }));
    
    setNewMessage('');
  };

  const getFilteredProposals = () => {
    if (!selectedEvent?.proposals) return [];
    
    return selectedEvent.proposals.filter(venue => {
      const price = venue?.price || 0;
      const capacityStr = venue?.capacity || '0-0';
      const availability = venue?.availability || '';
      
      const matchesPrice = 
        price >= filters.priceRange[0] &&
        price <= filters.priceRange[1];
        
      const [minCap, maxCap] = capacityStr.split('-');
      const capacity = parseInt(maxCap || minCap || 0);
      
      const matchesCapacity = 
        capacity >= filters.capacity[0] &&
        capacity <= filters.capacity[1];
        
      const matchesAvailability = 
        filters.availability.length === 0 || 
        filters.availability.includes(availability);
        
      const matchesSearch = !filters.search || 
        venue?.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        venue?.details?.space?.toLowerCase().includes(filters.search.toLowerCase());
        
      return matchesPrice && matchesCapacity && matchesAvailability && matchesSearch;
    });
  };

  const applyFilters = () => {
    setFilters(filters);
    setShowFilters(false);
    setIsFilterActive(true);
  };

  const clearAllFilters = () => {
    const defaultFilters = {
      priceRange: [0, 50000],
      capacity: [0, 300],
      availability: []
    };
    setFilters(defaultFilters);
    setIsFilterActive(false);
    setShowFilters(false);
  };

  const getProposalsCount = (event) => {
    return event.proposals?.length || 0;
  };

  const handleRangeChange = (filterName, index, value) => {
    setFilters(prev => {
      const newRange = [...prev[filterName]];
      newRange[index] = parseInt(value) || 0;
      return { ...prev, [filterName]: newRange };
    });
  };

  const handleAvailabilityChange = (option) => {
    setFilters(prev => ({
      ...prev,
      availability: prev.availability.includes(option)
        ? prev.availability.filter(a => a !== option)
        : [...prev.availability, option]
    }));
  };

  return (
    <MainLayout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Host Dashboard</h1>
          <motion.button 
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="flex items-center space-x-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-fuchsia-600 text-white rounded-lg transition-all duration-200 shadow-md hover:shadow-lg"
          >
            <Plus className="w-5 h-5" />
            <span>Create New Event</span>
          </motion.button>
        </div>
        
        <div className="mb-6 relative">
          <input
            type="text"
            placeholder="Search events..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>

        <div className="overflow-x-auto bg-white rounded-lg shadow">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="p-4 text-left">
                  <button className="flex items-center text-gray-600 hover:text-gray-900" onClick={() => handleSort('name')}>
                    Event Name
                    <ArrowUpDown size={16} className="ml-1" />
                  </button>
                </th>
                <th className="p-4 text-left">
                  <button className="flex items-center text-gray-600 hover:text-gray-900" onClick={() => handleSort('date')}>
                    Date
                    <ArrowUpDown size={16} className="ml-1" />
                  </button>
                </th>
                <th className="p-4 text-left">
                  <button className="flex items-center text-gray-600 hover:text-gray-900" onClick={() => handleSort('budget')}>
                    Budget
                    <ArrowUpDown size={16} className="ml-1" />
                  </button>
                </th>
                <th className="p-4 text-left">
                  <button className="flex items-center text-gray-600 hover:text-gray-900" onClick={() => handleSort('guests')}>
                    Guests
                    <ArrowUpDown size={16} className="ml-1" />
                  </button>
                </th>
                <th className="p-4 text-left">Status</th>
                <th className="p-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {filteredAndSortedEvents.map((event) => (
                  <React.Fragment key={event.id}>
                    <motion.tr
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="border-b hover:bg-gray-50"
                    >
                      <td className="p-4">{event.name}</td>
                      <td className="p-4">{new Date(event.date).toLocaleDateString()}</td>
                      <td className="p-4">${event.budget.toLocaleString()}</td>
                      <td className="p-4">{event.guests.toLocaleString()}</td>
                      <td className="p-4">
                        <span className={`px-2 py-1 rounded-full text-sm font-medium ${
                          event.status === 'Confirmed' 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {event.status}
                        </span>
                      </td>
                      <td className="p-4">
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => toggleEventDetails(event.id)}
                          className="text-purple-600 hover:text-purple-800 font-medium"
                        >
                          {expandedEvent === event.id ? 'Hide Details' : 'Show Details'}
                        </motion.button>
                      </td>
                    </motion.tr>
                    {expandedEvent === event.id && (
                      <motion.tr
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <td colSpan="6">
                          <motion.div 
                            className="p-4 bg-gray-50 space-y-4"
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                          >
                            {/* Event Details Grid */}
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Date & Time</h4>
                                <p className="text-gray-900">{new Date(event.date).toLocaleDateString()}</p>
                                <p className="text-gray-900">{event.requirements.timing}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Budget</h4>
                                <p className="text-gray-900">${event.budget.toLocaleString()}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Guest Count</h4>
                                <p className="text-gray-900">{event.guests} people</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Location</h4>
                                <p className="text-gray-900">{event.location}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Status</h4>
                                <p className="text-gray-900">{event.status}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-500">Proposals</h4>
                                <p className="text-gray-900">{getProposalsCount(event)} proposals received</p>
                              </div>
                            </div>

                            {/* Requirements Grid */}
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4 bg-white p-4 rounded-lg">
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Space Needs</h4>
                                <p className="text-gray-700">{event.requirements.space}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Vibe</h4>
                                <p className="text-gray-700">{event.requirements.vibe}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Food</h4>
                                <p className="text-gray-700">{event.requirements.food}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Bar</h4>
                                <p className="text-gray-700">{event.requirements.bar}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Extras</h4>
                                <p className="text-gray-700">{event.requirements.extras}</p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-purple-600">Description</h4>
                                <p className="text-gray-700">{event.description}</p>
                              </div>
                            </div>

                            <div className="mt-4 flex justify-between items-center">
                              <span className="text-gray-600">
                                {event.proposalsReceived} proposals received
                              </span>
                              <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => handleViewProposals(event)}
                                className="px-4 py-2 bg-gradient-to-r from-purple-600 to-fuchsia-600 text-white rounded-lg transition-all duration-200"
                              >
                                View Proposals
                              </motion.button>
                            </div>
                          </motion.div>
                        </td>
                      </motion.tr>
                    )}
                  </React.Fragment>
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      </div>
      {showProposalsModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95 }}
            className="bg-white rounded-xl shadow-xl max-w-5xl w-full max-h-[80vh] overflow-auto relative"
          >
            <button
              onClick={() => setShowProposalsModal(false)}
              className="absolute top-0.5 right-0.5 text-gray-400 hover:text-gray-600 z-10 p-1 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Close modal"
            >
              <XCircle size={16} strokeWidth={1.5} />
            </button>
            
            {!selectedVenue ? (
              <div className="p-6 min-h-[600px]">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h2 className="text-2xl font-bold">
                      Venue Proposals ({getFilteredProposals().length})
                    </h2>
                    <p className="text-sm text-gray-500 mt-1">Compare venues side by side</p>
                  </div>
                  
                  <div className="flex items-center gap-2">
                    <div className="relative" ref={filtersRef}>
                      <button 
                        className="inline-flex items-center px-4 py-2 bg-white border rounded-lg text-sm gap-2 hover:bg-gray-50 transition-colors"
                        onClick={() => setShowFilters(!showFilters)}
                      >
                        <svg 
                          width="16" 
                          height="16" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                          className="text-gray-600"
                        >
                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                        </svg>
                        Filters
                        {isFilterActive && (
                          <span className="inline-flex items-center justify-center px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                            Active
                          </span>
                        )}
                      </button>
                      
                      {showFilters && (
                        <div className="absolute right-0 top-full mt-2 w-72 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                          <div className="p-4">
                            <div className="space-y-4">
                              <div>
                                <label className="text-sm font-medium text-gray-700">Price Range</label>
                                <div className="mt-2 flex items-center gap-2">
                                  <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.priceRange[0]}
                                    onChange={(e) => handleRangeChange('priceRange', 0, e.target.value)}
                                    className="w-full p-2 border rounded-md text-sm"
                                  />
                                  <span className="text-gray-500">to</span>
                                  <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.priceRange[1]}
                                    onChange={(e) => handleRangeChange('priceRange', 1, e.target.value)}
                                    className="w-full p-2 border rounded-md text-sm"
                                  />
                                </div>
                              </div>

                              <div>
                                <label className="text-sm font-medium text-gray-700">Capacity</label>
                                <div className="mt-2 flex items-center gap-2">
                                  <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.capacity[0]}
                                    onChange={(e) => handleRangeChange('capacity', 0, e.target.value)}
                                    className="w-full p-2 border rounded-md text-sm"
                                  />
                                  <span className="text-gray-500">to</span>
                                  <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.capacity[1]}
                                    onChange={(e) => handleRangeChange('capacity', 1, e.target.value)}
                                    className="w-full p-2 border rounded-md text-sm"
                                  />
                                </div>
                              </div>

                              <div>
                                <label className="text-sm font-medium text-gray-700">Availability</label>
                                <div className="mt-2 space-y-2">
                                  {['Available', 'Limited'].map(option => (
                                    <label key={option} className="flex items-center">
                                      <input
                                        type="checkbox"
                                        checked={filters.availability.includes(option)}
                                        onChange={() => handleAvailabilityChange(option)}
                                        className="mr-2"
                                      />
                                      <span className="text-sm text-gray-700">{option}</span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between items-center">
                              <button
                                onClick={clearAllFilters}
                                className="text-sm text-gray-600 hover:text-gray-900"
                              >
                                Clear
                              </button>
                              <button
                                onClick={applyFilters}
                                className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors"
                              >
                                Apply Filters
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {isFilterActive && (
                      <button 
                        onClick={clearAllFilters}
                        className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-600 border rounded-lg text-sm hover:bg-gray-200 transition-colors"
                      >
                        Clear Filters
                        <X className="w-4 h-4 ml-2" />
                      </button>
                    )}
                  </div>
                </div>

                <div className="overflow-x-auto mt-6">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Venue
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Capacity
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Details
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {getFilteredProposals().map((venue, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4">
                            <div>
                              <div className="font-medium">{venue.name}</div>
                              <div className="text-sm text-gray-500">{venue.details?.space}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            ${venue.price}
                            {venue.minimums && (
                              <div className="text-sm text-gray-500">+${venue.minimums}</div>
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <div>{venue.capacity}</div>
                            <div className="text-sm text-gray-500">Optimal</div>
                          </td>
                          <td className="px-6 py-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              venue.availability === 'Available' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                            }`}>
                              {venue.availability}
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <div className="text-sm">
                              <div>Includes: {venue.details?.includes}</div>
                              <div>Restrictions: {venue.details?.restrictions}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex flex-col gap-2">
                              <button 
                                onClick={() => setSelectedVenue(venue)}
                                className="text-purple-600 hover:text-purple-800 text-sm font-medium"
                              >
                                View Details
                              </button>
                              <button className="px-3 py-1.5 bg-gradient-to-r from-purple-600 to-fuchsia-600 text-white rounded-lg hover:opacity-90 transition-opacity text-sm">
                                Request Tour
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {getFilteredProposals().length === 0 && (
                        <tr>
                          <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                            No venues match your criteria
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold">{selectedVenue.name}</h2>
                  <button
                    onClick={() => setSelectedVenue(null)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    ←
                  </button>
                </div>
                
                {/* Venue's Pitch */}
                <div className="bg-purple-50 p-4 rounded-lg mb-6">
                  <h3 className="text-lg font-medium mb-2">Their Pitch</h3>
                  <p className="text-gray-700">{selectedVenue.pitch}</p>
                </div>

                {/* Venue Details */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div>
                    <h3 className="font-medium text-gray-900">Space Details</h3>
                    <p className="text-gray-600">{selectedVenue.details.space}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Includes</h3>
                    <p className="text-gray-600">{selectedVenue.details.includes}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Minimums</h3>
                    <p className="text-gray-600">{selectedVenue.details.minimums}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Restrictions</h3>
                    <p className="text-gray-600">{selectedVenue.details.restrictions}</p>
                  </div>
                </div>

                {/* Messages Section */}
                <div className="border-t pt-6">
                  <h3 className="text-lg font-medium mb-4">Messages</h3>
                  <div className="space-y-4 mb-4">
                    {selectedVenue.messages.map((message, index) => (
                      <div
                        key={index}
                        className={`flex ${message.from === 'user' ? 'justify-end' : 'justify-start'}`}
                      >
                        <div
                          className={`max-w-[80%] p-3 rounded-lg ${
                            message.from === 'user'
                              ? 'bg-purple-600 text-white'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          <p>{message.text}</p>
                          <p className="text-xs opacity-75 mt-1">
                            {new Date(message.timestamp).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  {/* Message Input */}
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Type your message..."
                      className="flex-1 p-2 border rounded-lg"
                    />
                    <button
                      onClick={handleSendMessage}
                      className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </MainLayout>
  );
} 