/**
 * Footer Component
 * 
 * Features:
 * - Responsive 3-column layout (1 column on mobile)
 * - Brand section with logo and description
 * - Quick navigation links
 * - Contact information
 * - Early access message for beta platform
 * - Copyright notice
 * 
 * Layout:
 * - Uses CSS Grid for column layout
 * - Responsive padding and spacing
 * - Max width container for content
 * 
 * Styling:
 * - Dark theme with black background
 * - Gray text with white hover states
 * - Subtle borders between sections
 * - Icon integration for contact methods
 * 
 * Notes:
 * - Social media links commented out for future implementation
 * - Uses dynamic year calculation for copyright
 * - Includes beta platform disclaimer
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';

export function Footer() {
  const currentYear = new Date().getFullYear();
  
  // Add this function to handle scrolling to top
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-black text-gray-400">
      <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Brand & Description */}
          <div className="space-y-4">
            <Link to="/" onClick={scrollToTop} className="flex items-center">
              <span className="text-xl font-bold text-white">
                LetsHost
              </span>
            </Link>
            <p className="text-sm text-gray-400">
              Transforming event planning by connecting hosts with their perfect venues.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-300 mb-4">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <Link 
                  to="/mission" 
                  onClick={scrollToTop}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Mission
                </Link>
              </li>
              <li>
                <Link 
                  to="/" 
                  onClick={scrollToTop}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Plan Your Event
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-sm font-semibold text-gray-300 mb-4">Contact</h3>
            <ul className="space-y-3">
              <li>
                <a 
                  href="mailto:alex@letshostnow.com" 
                  className="text-sm text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                >
                  <Mail className="h-4 w-4" />
                  alex@letshostnow.com
                </a>
              </li>
              {/* Social links commented out for future reference
              <li>
                <div className="flex items-center gap-4">
                  <a 
                    href="https://twitter.com/letshost" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <Twitter className="h-5 w-5" />
                  </a>
                  <a 
                    href="https://github.com/letshost" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <Github className="h-5 w-5" />
                  </a>
                </div>
              </li>
              */}
            </ul>
          </div>
        </div>

        {/* Early Access Message */}
        <div className="mt-8 pt-6 border-t border-gray-800">
          <p className="text-sm text-gray-500 text-center">
            ⓘ This is early access to our beta platform. Pardon any issues, please contact us with feedback.
          </p>
        </div>

        {/* Copyright */}
        <div className="mt-4 pt-4 border-t border-gray-800">
          <p className="text-sm text-gray-500 text-center">
            © {currentYear} LetsHost. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
} 