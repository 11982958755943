import React, { useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Slider,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

// Tooltip component with improved styling
const InfoTooltip = ({ text }) => (
  <div className="group relative inline-block ml-2">
    <div className="cursor-help">
      <svg 
        className="w-5 h-5 text-gray-400" 
        fill="currentColor" 
        viewBox="0 0 20 20"
      >
        <path 
          fillRule="evenodd" 
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-1 1v1a1 1 0 102 0V8a1 1 0 00-1-1zm0 5a1 1 0 100 2 1 1 0 000-2z" 
          clipRule="evenodd" 
        />
      </svg>
    </div>
    <div className="invisible group-hover:visible absolute z-50 w-64 p-4 mt-2 text-sm 
      bg-white rounded-lg shadow-lg border border-gray-200 left-1/2 transform -translate-x-1/2
      whitespace-pre-line">
      {text}
    </div>
  </div>
);

// Define group size tiers and their base multipliers
const GROUP_TIERS = {
  tier1: { max: 10, multiplier: 1.2, name: '10' },
  tier2: { max: 20, multiplier: 1.1, name: '20' },
  tier3: { max: 30, multiplier: 1.0, name: '30' },
  tier4: { max: 40, multiplier: 0.95, name: '40' },
  tier5: { max: 50, multiplier: 0.9, name: '50' },
  tier6: { max: 75, multiplier: 0.85, name: '75' },
  tier7: { max: 100, multiplier: 0.8, name: '100' },
  tier8: { max: 150, multiplier: 0.75, name: '150' },
  tier9: { max: 200, multiplier: 0.7, name: '200' },
  tier10: { max: 500, multiplier: 0.65, name: '500' }
};

// Define the exact pricing matrix from the table
const PRICING_MATRIX = {
  '10': {
    2: 350,
    3: 450,
    4: 500,
    5: 550,
    6: 600,
    7: 650,
    8: 700
  },
  '20': {
    2: 1000,
    3: 1250,
    4: 1350,
    5: 1500,
    6: 1650,
    7: 1750,
    8: 1900
  },
  '30': {
    2: 1750,
    3: 2200,
    4: 2375,
    5: 2625,
    6: 2900,
    7: 3075,
    8: 3325
  },
  '40': {
    2: 2250,
    3: 2800,
    4: 3025,
    5: 3350,
    6: 3700,
    7: 3925,
    8: 4250
  },
  '50': {
    2: 2800,
    3: 3500,
    4: 3780,
    5: 4200,
    6: 4620,
    7: 4900,
    8: 5320
  },
  '75': {
    2: 3850,
    3: 4800,
    4: 5200,
    5: 5775,
    6: 6350,
    7: 6750,
    8: 7325
  },
  '100': {
    2: 5250,
    3: 6550,
    4: 7100,
    5: 7875,
    6: 8650,
    7: 9200,
    8: 9975
  },
  '150': {
    2: 7000,
    3: 8750,
    4: 9450,
    5: 10500,
    6: 11550,
    7: 12250,
    8: 13300
  },
  '200': {
    2: 9450,
    3: 11800,
    4: 12750,
    5: 14175,
    6: 15600,
    7: 16550,
    8: 17975
  },
  '500': {
    2: 12600,
    3: 15750,
    4: 17000,
    5: 18900,
    6: 20800,
    7: 22050,
    8: 23950
  }
};

// Define duration options consistently
const DURATION_OPTIONS = [
  "2 hours",
  "3 hours",
  "4 hours",
  "5 hours",
  "6 hours",
  "7 hours",
  "8 hours"
];

// New simplified multipliers
const MULTIPLIERS = {
  // Venue type multipliers (upscale is baseline)
  venueType: {
    casual: 0.85,    // 15% less than upscale
    upscale: 1.0,    // baseline
    luxury: 1.20     // 20% more than upscale
  },
  
  // Day multipliers
  eventDay: {
    weekday: 1.0,
    weekend: 1.25
  },
  
  // Location multipliers
  location: {
    manhattan: 1.1,
    brooklyn: 1.0,
    queens: .9
  },
  
  // Food tier costs (per person)
  foodTier: {
    none: 0,
    appetizers: 25,    // Reduced from 35 to 25
    buffet: 45,        // Reduced from 65 to 45
    plated: 65         // Reduced from 95 to 65
  },
  
  // Bar package costs (per person per hour)
  barPackage: {
    noBar: 0,
    limited: 20,      // Was 25, now 20 per person per hour
    standard: 30,     // Was 45, now 30 per person per hour
    premium: 40       // Was 65, now 40 per person per hour
  }
};

const VENUE_ONLY_PRICING = {
  '10': {
    2: 200,
    3: 250,
    4: 300,
    5: 350,
    6: 400,
    7: 450,
    8: 500
  },
  '20': {
    2: 600,
    3: 750,
    4: 900,
    5: 1050,
    6: 1200,
    7: 1350,
    8: 1500
  },
  '30': {
    2: 1000,
    3: 1250,
    4: 1500,
    5: 1750,
    6: 2000,
    7: 2250,
    8: 2500
  },
  '40': {
    2: 1200,
    3: 1500,
    4: 1800,
    5: 2100,
    6: 2400,
    7: 2700,
    8: 3000
  },
  '50': {
    2: 1500,
    3: 1875,
    4: 2250,
    5: 2625,
    6: 3000,
    7: 3375,
    8: 3750
  },
  '75': {
    2: 2000,
    3: 2500,
    4: 3000,
    5: 3500,
    6: 4000,
    7: 4500,
    8: 5000
  },
  '100': {
    2: 2500,
    3: 3125,
    4: 3750,
    5: 4375,
    6: 5000,
    7: 5625,
    8: 6250
  },
  '150': {
    2: 3500,
    3: 4375,
    4: 5250,
    5: 6125,
    6: 7000,
    7: 7875,
    8: 8750
  },
  '200': {
    2: 4500,
    3: 5625,
    4: 6750,
    5: 7875,
    6: 9000,
    7: 10125,
    8: 11250
  },
  '500': {
    2: 6000,
    3: 7500,
    4: 9000,
    5: 10500,
    6: 12000,
    7: 13500,
    8: 15000
  }
};

// Update tiered venue fee structure
const VENUE_FEE_TIERS = {
  tier1: { max: 5000, rate: 0.05 },      // 5.0% for base costs up to $5,000
  tier2: { max: 10000, rate: 0.045 },    // 4.5% for base costs $5,001-$10,000
  tier3: { max: 15000, rate: 0.04 },     // 4.0% for base costs $10,001-$15,000
  tier4: { max: 20000, rate: 0.035 },    // 3.5% for base costs $15,001-$20,000
  tier5: { max: Infinity, rate: 0.03 }   // 3.0% for base costs $20,001+
};

// Helper function to get the correct guest range key
const getGuestRangeKey = (guestCount) => {
  // Convert string to number if needed
  const count = Number(guestCount);
  
  // Validate input
  if (isNaN(count) || count < 1) return null;
  
  // Check ranges in order
  if (count <= 10) return '10';
  if (count <= 20) return '20';
  if (count <= 30) return '30';
  if (count <= 40) return '40';
  if (count <= 50) return '50';
  if (count <= 75) return '75';
  if (count <= 100) return '100';
  if (count <= 150) return '150';
  if (count <= 200) return '200';
  return '500';
};

// First, ensure VenueStyleSelector is properly defined and exported
const VenueStyleSelector = ({ formData, handleInputChange }) => (
  <div className="p-1">
    <div className="text-gray-600 mb-2">Venue Style</div>
    <div className="grid grid-cols-3 gap-3">
      {[
        { name: 'Casual', value: 'casual' },
        { name: 'Upscale', value: 'upscale' },
        { name: 'Luxury', value: 'luxury' }
      ].map((style) => (
        <div 
          key={style.value}
          onClick={() => handleInputChange('venueType', style.value)}
          className={`p-3 rounded-lg text-center transition-all duration-300 hover:scale-105 cursor-pointer
            ${formData.venueType === style.value 
              ? 'bg-gradient-to-br from-purple-100 to-pink-100 border-2 border-purple-300 shadow-md' 
              : 'bg-gray-50 border-2 border-gray-200 hover:border-gray-300'}`}
        >
          <div className="text-gray-800 text-sm font-medium">
            {style.name}
          </div>
        </div>
      ))}
    </div>
  </div>
);

// Update scaling factors to match F&B pricing matrix ratios
const DURATION_SCALING = {
  2: 1.0,    // Base rate (matches F&B matrix)
  3: 0.95,   // ~5% decrease per hour
  4: 0.90,
  5: 0.85,
  6: 0.80,
  7: 0.75,
  8: 0.70    // Longest duration gets best rate
};

const GUEST_COUNT_SCALING = {
  10: 1.2,    // Matches GROUP_TIERS multiplier
  20: 1.1,
  30: 1.0,    // Base rate
  40: 0.95,
  50: 0.9,
  75: 0.85,
  100: 0.8,
  150: 0.75,
  200: 0.7,
  500: 0.65   // Matches the largest group tier multiplier
};

export default function PricingCalculator() {
  // Initialize all costs with proper default values
  const [costs, setCosts] = useState({
    baseCost: 0,
    foodCost: 0,
    barCost: 0,
    adminFee: 0,
    gratuity: 0,
    tax: 0,
    total: 0,
    subtotal: 0,
    breakdown: {
      foodCostPerPerson: 0,
      barMinimum: 0,
      totalDrinksCost: 0,
      totalFoodCost: 0,
      baseVenueCost: 0
    }
  });

  // Initialize form data with default values
  const [formData, setFormData] = useState({
    venueRentalOnly: false,
    barPricingType: 'barMinimum',
    location: '',
    numGuests: '',
    duration: '',
    foodTier: 'none',
    liquorQuality: '',
    venueType: 'upscale',
    eventDay: ''
  });

  // Show/hide bar-specific options based on bar type
  const showDrinkTickets = formData.barPricingType === 'drinkTickets';
  const showLiquorQuality = formData.barPricingType === 'openBar';

  const calculateCost = useCallback((currentFormData) => {
    const {
      numGuests,
      duration,
      eventDay,
      location,
      barPricingType,
      liquorQuality,
      foodTier,
      venueType,
      venueRentalOnly
    } = currentFormData;

    if (!numGuests || !duration || !eventDay || !location) return {};

    const durationHours = parseInt(duration.match(/(\d+)/)[1]);
    const guestRange = getGuestRangeKey(numGuests);
    
    let adjustedVenueCost = 0;
    let barCost = 0;
    let foodCost = 0;
    const dayMultiplier = MULTIPLIERS.eventDay[eventDay] || 1.0;
    const locationMultiplier = MULTIPLIERS.location[location] || 1.0;

    if (venueRentalOnly) {
      // Venue rental only calculations
      adjustedVenueCost = VENUE_ONLY_PRICING[guestRange][durationHours];
      adjustedVenueCost *= MULTIPLIERS.venueType[venueType] || 1.0;
      adjustedVenueCost *= dayMultiplier;
      adjustedVenueCost *= locationMultiplier;
      
      // Ensure bar and food costs are zero
      barCost = 0;
      foodCost = 0;
    } else {
      // F&B service calculations
      adjustedVenueCost = 0; // No venue rental fee for F&B services
      if (barPricingType === 'barMinimum') {
        barCost = PRICING_MATRIX[guestRange][durationHours];
        barCost *= MULTIPLIERS.venueType[venueType] || 1.0;
        barCost *= dayMultiplier;
        barCost *= locationMultiplier;
      } else if (barPricingType === 'openBar' && liquorQuality) {
        const barCostPerPersonPerHour = MULTIPLIERS.barPackage[liquorQuality] || 0;
        barCost = barCostPerPersonPerHour * Number(numGuests) * durationHours;
        barCost *= MULTIPLIERS.venueType[venueType] || 1.0;
        barCost *= dayMultiplier;
        barCost *= locationMultiplier;

        if (foodTier && foodTier !== 'none') {
          const foodCostPerPerson = MULTIPLIERS.foodTier[foodTier] || 0;
          foodCost = foodCostPerPerson * Number(numGuests);
          foodCost *= MULTIPLIERS.venueType[venueType] || 1.0;
          foodCost *= locationMultiplier;
        }
      }
    }

    // Calculate fees
    const baseCosts = {
      venue: adjustedVenueCost,
      food: foodCost,
      bar: barCost
    };

    const serviceCosts = {
      total: foodCost + barCost
    };

    const totalBaseCost = baseCosts.venue + baseCosts.food + baseCosts.bar;
    const venueFeeRate = calculateVenueFeeRate(totalBaseCost);
    const adminFee = totalBaseCost * venueFeeRate;
    const gratuity = serviceCosts.total * 0.20;
    const taxableAmount = totalBaseCost + adminFee + gratuity;
    const tax = taxableAmount * 0.08875;

    return {
      baseCost: adjustedVenueCost,
      foodCost,
      barCost,
      adminFee,
      gratuity,
      tax,
      total: taxableAmount + tax
    };
  }, []);

  // Handle input changes
  const handleInputChange = useCallback((field, value) => {
    setFormData(prev => {
      const newFormData = {
        ...prev,
        [field]: value
      };

      // Handle venue rental only toggle
      if (field === 'venueRentalOnly') {
        if (value === true) {
          // If switching to venue rental only, reset bar and food options
          newFormData.barPricingType = '';
          newFormData.foodTier = 'none';
          newFormData.liquorQuality = '';
        } else {
          // If switching from venue rental only, set default bar minimum
          newFormData.barPricingType = 'barMinimum';
        }
      }

      // Handle other service type changes
      if (field === 'barPricingType') {
        if (value === 'barMinimum') {
          newFormData.foodTier = 'none';
          newFormData.liquorQuality = '';
        } else if (value === 'openBar') {
          newFormData.liquorQuality = 'standard';
        }
      }

      // Only calculate if we have the minimum required fields
      if (newFormData.numGuests && newFormData.duration && newFormData.eventDay && newFormData.location) {
        const newCosts = calculateCost(newFormData);
        setCosts(newCosts);
      } else {
        setCosts({}); // Reset costs if missing required fields
      }

      return newFormData;
    });
  }, [calculateCost]);

  // Initial calculation and recalculation when form data changes
  useEffect(() => {
    if (isValidInput(formData)) {
      const newCosts = calculateCost(formData);
      setCosts(newCosts);
    }
  }, [formData, calculateCost]);

  // Define colors at component level
  const CHART_COLORS = [
    '#4F46E5', // indigo
    '#10B981', // emerald
    '#F59E0B', // amber
    '#EF4444', // red
    '#8B5CF6', // purple
    '#EC4899'  // pink
  ];

  const pieChartData = [
    { name: 'Base Cost', value: costs.baseCost },
    { name: 'Bar Cost', value: costs.barCost },
    { name: 'Food Cost', value: costs.foodCost },
    { name: 'Venue Fee', value: costs.adminFee },
    { name: 'Tip', value: costs.gratuity },
    { name: 'Taxes', value: costs.tax }
  ].filter(item => item.value > 0); // Only show non-zero values

  // Add input validation function
  const isValidInput = (formData) => {
    return Boolean(
      formData.barPricingType &&
      formData.eventDay &&
      formData.location &&
      formData.numGuests &&
      formData.foodTier
    );
  };

  // Update tier calculation with validation check
  const calculatePricing = (formData) => {
    if (!isValidInput(formData)) {
      return null; // Skip calculations if inputs are invalid
    }

    const groupTier = Object.values(GROUP_TIERS).find(
      (tier) => formData.numGuests >= tier.min && formData.numGuests <= tier.max
    ) || GROUP_TIERS.tier1;
    
    // ... rest of calculation logic ...
  };

  // Add this helper function near the top of the component
  const roundTo25 = (num) => Math.round(num / 25) * 25;

  // Add this helper function near your other calculations
  const calculatePerGuestCost = (total, numGuests, foodTier, barPricingType) => {
    // Ensure we have valid inputs
    if (!total || !numGuests) return 0;

    // Minimum realistic per-guest costs based on services
    const minimumPerGuest = {
      noServices: 15,  // Minimum for venue-only
      foodOnly: 35,    // Minimum with food
      barOnly: 30,     // Minimum with bar
      fullService: 50  // Minimum with both food and bar
    };

    // Determine service level
    const hasFood = foodTier !== 'none';
    const hasBar = barPricingType !== 'noBar';
    
    let serviceLevel = 'noServices';
    if (hasFood && hasBar) serviceLevel = 'fullService';
    else if (hasFood) serviceLevel = 'foodOnly';
    else if (hasBar) serviceLevel = 'barOnly';

    // Calculate raw per-guest cost
    const rawPerGuest = total / numGuests;

    // Apply minimum based on service level
    const minCost = minimumPerGuest[serviceLevel];
    
    // For very small groups (under 20), we need to adjust the minimum up
    const smallGroupMultiplier = numGuests < 20 ? (1 + (20 - numGuests) * 0.05) : 1;
    const adjustedMinCost = minCost * smallGroupMultiplier;

    // Return the higher of the calculated cost or adjusted minimum
    return Math.max(rawPerGuest, adjustedMinCost);
  };

  // Add an info tooltip to explain the guest count calculation
  const GuestCountTooltip = () => (
    <InfoTooltip 
      text={`Calculations are based on the average guest count for each range. 
      This helps provide more accurate cost estimates. The final cost may vary 
      based on your exact guest count.`} 
    />
  );

  // Update the cost breakdown display
  const CostBreakdown = ({ costs, formData }) => {
    // Calculate per guest cost using total and guest count
    const perGuestCost = formData.numGuests 
      ? Math.round(costs.total / formData.numGuests) 
      : 0;

    return (
      <div className="space-y-2">
        {/* Base Costs */}
        <div className="flex justify-between items-center">
          <span className="text-gray-700">Base Cost</span>
          <span className="text-gray-900">${costs.baseCost.toLocaleString()}</span>
        </div>

        {/* Bar Cost */}
        {costs.barCost > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Bar Cost</span>
            <span className="text-gray-900">${costs.barCost.toLocaleString()}</span>
          </div>
        )}

        {/* Food Cost */}
        {costs.foodCost > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Food Cost</span>
            <span className="text-gray-900">${costs.foodCost.toLocaleString()}</span>
          </div>
        )}

        {/* Venue Fee */}
        {costs.adminFee > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Venue Fee</span>
            <span className="text-gray-900">${costs.adminFee.toLocaleString()}</span>
          </div>
        )}

        {/* Gratuity */}
        {costs.gratuity > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Gratuity</span>
            <span className="text-gray-900">${costs.gratuity.toLocaleString()}</span>
          </div>
        )}

        {/* Taxes */}
        <div className="flex justify-between items-center">
          <span className="text-gray-700">Taxes</span>
          <span className="text-gray-900">${costs.tax.toLocaleString()}</span>
        </div>

        {/* Total and Per Guest Cost */}
        <div className="border-t border-gray-200 mt-4 pt-4">
          <div className="flex justify-between text-lg font-semibold">
            <span className="text-gray-800">Total</span>
            <span className="text-gray-800">${costs.total.toLocaleString()}</span>
          </div>
          {perGuestCost > 0 && (
            <div className="flex justify-between text-sm text-gray-600 mt-1">
              <span>Per Guest</span>
              <span>${perGuestCost.toLocaleString()}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Update the EstimatedBudget component
  const EstimatedBudget = ({ costs, formData }) => (
    <div className="space-y-4">
      <div className="text-3xl text-purple-600 font-bold text-center">
        ${costs.perGuestCost || 0}
        <div className="text-sm text-gray-600 font-normal">per guest</div>
      </div>

      <div className="space-y-3">
        {/* Only show space rental fee if NO food AND NO bar service */}
        {!costs.barCost && !costs.foodCost && costs.baseCost > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Space Rental Fee</span>
            <span className="text-gray-900">${costs.baseCost?.toLocaleString() || 0}</span>
          </div>
        )}

        {/* Bar Cost */}
        {costs.barCost > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Bar Cost</span>
            <span className="text-gray-900">${costs.barCost?.toLocaleString() || 0}</span>
          </div>
        )}

        {/* Food Cost */}
        {costs.foodCost > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Food Cost</span>
            <span className="text-gray-900">${costs.foodCost?.toLocaleString() || 0}</span>
          </div>
        )}

        {/* Venue Fee */}
        {costs.adminFee > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Venue Fee</span>
            <span className="text-gray-900">${costs.adminFee?.toLocaleString() || 0}</span>
          </div>
        )}

        {/* Gratuity */}
        {costs.gratuity > 0 && (
          <div className="flex justify-between items-center">
            <span className="text-gray-700">Gratuity</span>
            <span className="text-gray-900">${costs.gratuity?.toLocaleString() || 0}</span>
          </div>
        )}

        {/* Always show taxes */}
        <div className="flex justify-between items-center">
          <span className="text-gray-700">Taxes</span>
          <span className="text-gray-900">${costs.tax?.toLocaleString() || 0}</span>
        </div>

        <div className="border-t border-gray-200 pt-3 mt-3">
          <div className="flex justify-between items-center text-lg font-semibold">
            <span className="text-gray-800">Total</span>
            <span className="text-purple-600">${costs.total?.toLocaleString() || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );

  /**
   * Rounds a number to the nearest $50
   * @param {number} value - Value to round
   * @returns {number} Value rounded to nearest $50
   */
  const roundToNearest50 = (value) => {
    return Math.round(value / 50) * 50;
  };

  // Update all Material-UI form components to be smaller
  const muiCommonStyles = {
    size: "small",
    sx: {
      maxWidth: '300px',
      '& .MuiOutlinedInput-root': {
        height: '40px', // Make inputs shorter
        '& fieldset': {
          borderColor: 'rgba(107, 114, 128, 0.2)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(107, 114, 128, 0.4)',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#8B5CF6',
        },
      },
      '& .MuiInputLabel-root': {
        color: 'rgb(107, 114, 128)',
        transform: 'translate(14px, 8px) scale(1)', // Adjust label position for smaller height
        '&.Mui-focused, &.MuiFormLabel-filled': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },
  };

  // Rest of the code remains the same
  const calculateVenueFeeRate = (baseTotal) => {
    for (const tier of Object.values(VENUE_FEE_TIERS)) {
      if (baseTotal <= tier.max) {
        return tier.rate;
      }
    }
    return VENUE_FEE_TIERS.tier5.rate;
  };

  const COLORS = ['#4F46E5', '#7C3AED', '#EC4899', '#8B5CF6', '#6366F1'];

  const generateChartData = (costs) => {
    return [
      {
        name: 'Food & Beverage',
        value: roundTo25(costs.barCost + costs.foodCost || 0)
      },
      {
        name: 'Rental Fee',
        value: roundTo25(costs.baseCost || 0)
      },
      {
        name: 'Venue Fee',
        value: roundTo25(costs.adminFee || 0)
      },
      {
        name: 'Gratuity',
        value: roundTo25(costs.gratuity || 0)
      },
      {
        name: 'Taxes',
        value: roundTo25(costs.tax || 0)
      }
    ].filter(item => item.value > 0);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
      <Navbar />
      
      <main className="max-w-7xl mx-auto px-4 py-12 grid grid-cols-1 lg:grid-cols-[400px,1fr] gap-8">
        {/* Left Column - Input Form */}
        <div className="space-y-4">
          <h1 className="text-xl font-bold text-gray-900">Event Budget Calculator</h1>
          
          {/* Main Form Section */}
          <div className="grid grid-cols-2 gap-4">
            {/* Core Details Section */}
            <FormControl fullWidth size="small">
              <InputLabel>Guests</InputLabel>
              <Select
                value={formData.numGuests}
                onChange={(e) => handleInputChange('numGuests', e.target.value)}
                label="Guests"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                {Object.values(GROUP_TIERS).map((tier) => (
                  <MenuItem key={tier.name} value={tier.max}>
                    {tier.max}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Duration</InputLabel>
              <Select
                value={formData.duration}
                onChange={(e) => handleInputChange('duration', e.target.value)}
                label="Duration"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                {DURATION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Day</InputLabel>
              <Select
                value={formData.eventDay}
                onChange={(e) => handleInputChange('eventDay', e.target.value)}
                label="Day"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                <MenuItem value="weekday">Weekday</MenuItem>
                <MenuItem value="weekend">Weekend</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Location</InputLabel>
              <Select
                value={formData.location}
                onChange={(e) => handleInputChange('location', e.target.value)}
                label="Location"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                <MenuItem value="manhattan">Manhattan</MenuItem>
                <MenuItem value="brooklyn">Brooklyn</MenuItem>
                <MenuItem value="queens">Queens</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Venue Style Section */}
          <VenueStyleSelector formData={formData} handleInputChange={handleInputChange} />

          {/* Service Type Section */}
          <div className="space-y-3">
            <FormControlLabel
              control={
                <Switch
                  checked={formData.venueRentalOnly}
                  onChange={(e) => handleInputChange('venueRentalOnly', e.target.checked)}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#6366F1',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#6366F1',
                    },
                  }}
                />
              }
              label="Venue Rental Only"
              className="text-gray-700"
            />

            {!formData.venueRentalOnly && (
              <div className="space-y-3">
                <FormControl fullWidth size="small">
                  <InputLabel>Service Type</InputLabel>
                  <Select
                    value={formData.barPricingType}
                    onChange={(e) => handleInputChange('barPricingType', e.target.value)}
                    label="Service Type"
                    sx={{
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E5E7EB',
                      }
                    }}
                  >
                    <MenuItem value="barMinimum">Food & Beverage Minimum</MenuItem>
                    <MenuItem value="openBar">Open Bar</MenuItem>
                  </Select>
                </FormControl>

                {formData.barPricingType === 'openBar' && (
                  <>
                    <FormControl fullWidth size="small">
                      <InputLabel>Bar Package</InputLabel>
                      <Select
                        value={formData.liquorQuality}
                        onChange={(e) => handleInputChange('liquorQuality', e.target.value)}
                        label="Bar Package"
                        sx={{
                          backgroundColor: 'white',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E5E7EB',
                          }
                        }}
                      >
                        <MenuItem value="limited">
                          <div className="py-1">
                            <div>Limited (Beer & Wine)</div>
                            <div className="text-xs text-gray-500">Selection of beer and wine only</div>
                          </div>
                        </MenuItem>
                        <MenuItem value="standard">
                          <div className="py-1">
                            <div>Standard Bar</div>
                            <div className="text-xs text-gray-500">Basic spirits, beer, and wine</div>
                          </div>
                        </MenuItem>
                        <MenuItem value="premium">
                          <div className="py-1">
                            <div>Premium Bar</div>
                            <div className="text-xs text-gray-500">Top-shelf spirits, craft beer, and premium wines</div>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth size="small">
                      <InputLabel>Food Options</InputLabel>
                      <Select
                        value={formData.foodTier}
                        onChange={(e) => handleInputChange('foodTier', e.target.value)}
                        label="Food Options"
                        sx={{
                          backgroundColor: 'white',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E5E7EB',
                          }
                        }}
                      >
                        <MenuItem value="none">No Food</MenuItem>
                        <MenuItem value="appetizers">Appetizers</MenuItem>
                        <MenuItem value="buffet">Buffet</MenuItem>
                        <MenuItem value="plated">Plated Dinner</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Results */}
        <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100">
          {/* Top Row - Total and Chart side by side */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Total Cost Display */}
            <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-lg p-4 text-white">
              <h2 className="text-base font-medium text-indigo-100 mb-1">Total Event Cost</h2>
              <div className="text-3xl font-bold">
                ${roundTo25(costs.total || 0).toLocaleString()}
              </div>
              <div className="text-xs text-indigo-200 mt-1">
                {formData.numGuests && `${formData.numGuests} guests • `}
                {formData.duration && `${formData.duration} • `}
                {formData.eventDay === 'weekend' ? 'Weekend' : 'Weekday'}
              </div>
            </div>

            {/* Per Guest Cost Card */}
            <div className="bg-gradient-to-r from-purple-50 to-pink-50 rounded-lg p-4 border border-purple-100">
              <div className="text-center space-y-2">
                <div className="text-sm text-gray-600">Cost per Guest</div>
                <div className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">
                  ${formData.numGuests ? (
                    (roundTo25(costs.total || 0) / parseInt(formData.numGuests)).toFixed(2)
                  ) : '0.00'}
                </div>
                
                {/* Show hourly bar cost when open bar is selected */}
                {formData.barPricingType === 'openBar' && formData.liquorQuality && (
                  <div className="text-xs space-y-1 mt-1 py-1 px-2 bg-white/50 rounded-md">
                    <div className="text-purple-600 font-medium">Open Bar Rate</div>
                    <div className="flex items-center justify-center gap-1">
                      <span className="text-gray-500">
                        ${(MULTIPLIERS.barPackage[formData.liquorQuality] * 
                           MULTIPLIERS.venueType[formData.venueType] * 
                           MULTIPLIERS.location[formData.location] *
                           DURATION_SCALING[parseInt(formData.duration)] *
                           GUEST_COUNT_SCALING[formData.numGuests]).toFixed(0)}
                      </span>
                      <span className="text-gray-400">/hour</span>
                      <span className="text-gray-400">•</span>
                      <span className="text-gray-500">
                        ${(MULTIPLIERS.barPackage[formData.liquorQuality] * 
                           MULTIPLIERS.venueType[formData.venueType] * 
                           MULTIPLIERS.location[formData.location] *
                           DURATION_SCALING[parseInt(formData.duration)] *
                           GUEST_COUNT_SCALING[formData.numGuests] * 
                           parseInt(formData.duration)).toFixed(0)}
                      </span>
                      <span className="text-gray-400">/total</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Bottom Row - Chart and Breakdown side by side */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Cost Breakdown Chart */}
            <div className="order-2 sm:order-1">
              <h3 className="text-sm font-medium text-gray-900 mb-2">Cost Breakdown</h3>
              <div className="h-[180px] sm:h-[200px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={generateChartData(costs)}
                      cx="50%"
                      cy="50%"
                      innerRadius={35}
                      outerRadius={65}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {generateChartData(costs).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip 
                      formatter={(value) => `$${value.toLocaleString()}`}
                      contentStyle={{ 
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        border: '1px solid #E5E7EB',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                      }}
                    />
                    <Legend 
                      verticalAlign="bottom" 
                      height={36}
                      formatter={(value) => <span className="text-xs text-gray-600">{value}</span>}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Detailed Cost Breakdown */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-2">Detailed Breakdown</h3>
              <div className="space-y-1">
                {[
                  formData.venueRentalOnly ? 
                    ['Venue Rental', roundTo25(costs.baseCost || 0)] :
                    ['Food & Beverage', roundTo25((costs.barCost + costs.foodCost) || 0)],
                  ['Venue Fee', roundTo25(costs.adminFee || 0)],
                  !formData.venueRentalOnly ? ['Gratuity', roundTo25(costs.gratuity || 0)] : null,
                  ['Sales Tax', roundTo25(costs.tax || 0)]
                ].filter(Boolean).map(([label, value]) => (
                  <div key={label} className="flex justify-between items-center p-2 rounded-md hover:bg-gray-50">
                    <span className="text-xs text-gray-600">{label}</span>
                    <span className="text-xs text-gray-900 font-medium">${value.toLocaleString()}</span>
                  </div>
                ))}
                
                <div className="pt-2 mt-2 border-t border-gray-200">
                  <div className="flex justify-between items-center p-2">
                    <span className="text-sm font-medium text-gray-900">Total</span>
                    <span className="text-sm font-bold text-purple-600">
                      ${roundTo25(costs.total || 0).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
} 