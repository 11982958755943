import dayjs from 'dayjs';

// Direct use of environment variables instead of config object
const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
const AIRTABLE_TABLE_NAME = process.env.REACT_APP_AIRTABLE_TABLE_NAME;
const AIRTABLE_TOKEN = process.env.REACT_APP_AIRTABLE_TOKEN;

// Validate environment variables
if (!AIRTABLE_BASE_ID || !AIRTABLE_TABLE_NAME || !AIRTABLE_TOKEN) {
  throw new Error('Missing required Airtable configuration');
}

const airtableService = {
  async submitEvent(eventDetails) {
    try {
      // Debug log to see the structure
      console.log('Full eventDetails:', eventDetails);
      console.log('Contact details:', eventDetails.contact);

      const fields = {
        "firstName": eventDetails.contact?.firstName || '',
        "lastName": eventDetails.contact?.lastName || '',
        "occasion": eventDetails.setScene?.occasion || '',
        "activity": eventDetails.setScene?.activity || '',
        "guestCount": eventDetails.setScene?.guestCount || '',
        "budget": eventDetails.setScene?.budget || '',
        "date": eventDetails.setScene?.date ? dayjs(eventDetails.setScene.date).format('YYYY-MM-DD') : undefined,
        "startTime": eventDetails.setScene?.startTime ? 
          dayjs(`2024-01-01T${eventDetails.setScene.startTime}`).format('h:mm A') : undefined,
        "duration": eventDetails.setScene?.duration || '',
        "dreamDescription": eventDetails.dream || '',
        "email": eventDetails.contact?.email || '',
        "phone": eventDetails.contact?.phone?.replace(/[^\d]/g, '') || '',
        "inspirationLink": eventDetails.inspiration?.inspirationLink || '',
        "InspirationUploadPhoto": eventDetails.inspiration?.blobUrl || '',
        "foodOption": eventDetails?.setScene?.foodAndBev?.foodOption || '',
        "drinkOption": eventDetails?.setScene?.foodAndBev?.drinkOption || ''
      };

      // Remove any undefined fields
      const cleanedFields = Object.fromEntries(
        Object.entries(fields).filter(([_, value]) => value !== undefined)
      );

      const formattedData = { fields: cleanedFields };

      console.log('Formatted data being sent:', formattedData);

      console.log('Sending to Airtable:', formattedData);

      const response = await fetch(
        `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${AIRTABLE_TOKEN}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            records: [formattedData]
          })
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Airtable error response:', errorText);
        throw new Error(`Airtable API error: ${response.status} - ${errorText}`);
      }

      const result = await response.json();
      console.log('Airtable success response:', result);
      return result;
    } catch (error) {
      console.error('Error submitting to Airtable:', error);
      throw error;
    }
  }
};

export default airtableService; 