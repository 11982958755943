import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion' // For animations
import { Upload, X, ChevronRight } from 'lucide-react' // Icons
import { useNavigate } from 'react-router-dom'
import ProgressTracker from '../components/ProgressTracker'
import MainLayout from '../layouts/MainLayout'
import { put } from '@vercel/blob' // For file uploads
import logger from '../utils/logger' // Custom logging utility
import { toast } from 'react-hot-toast' // Toast notifications

/**
 * InspirationUpload Component
 * Allows users to upload visual inspiration for their event
 * Features:
 * - Photo upload with preview
 * - URL input with validation
 * - Data persistence in localStorage
 * - Progress tracking
 * - Responsive layout
 * - URL normalization (adds https:// if missing)
 */
export default function InspirationUpload() {
  const navigate = useNavigate();
  // Core state management
  const [uploadedPhoto, setUploadedPhoto] = useState(null); // Stores photo preview URL
  const [inspirationLink, setInspirationLink] = useState(''); // Stores inspiration URL
  const [linkError, setLinkError] = useState(''); // Validation error message
  const [notification, setNotification] = useState(null); // Toast notification state
  const [isUploading, setIsUploading] = useState(false); // Upload in progress flag

  // Initial data load from localStorage
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    if (savedData.inspiration) {
      setUploadedPhoto(savedData.inspiration.uploadedPhoto);
      setInspirationLink(savedData.inspiration.inspirationLink || '');
    }
  }, []);

  // Secondary effect for logging and data loading
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    
    logger.group('Inspiration Upload');
    logger.info('Loading saved data', {
      hasSetScene: !!savedData.setScene,
      hasInspiration: !!savedData.inspiration,
      currentRoute: window.location.pathname
    });

    // Load saved data if it exists
    if (savedData.inspiration) {
      setUploadedPhoto(savedData.inspiration.uploadedPhoto);
      setInspirationLink(savedData.inspiration.inspirationLink || '');
      logger.success('Loaded existing inspiration data');
    }

    logger.groupEnd();
  }, []);

  // Handles file upload to Vercel Blob storage
  const uploadToVercelBlob = async (file) => {
    try {
      const filename = `inspirations/${Date.now()}-${file.name}`;
      const { url } = await put(filename, file, {
        access: 'public',
        token: process.env.REACT_APP_BLOB_READ_WRITE_TOKEN
      });

      console.log('Upload successful, URL:', url);
      return url;
    } catch (error) {
      console.error('Error uploading to Vercel Blob:', {
        message: error.message,
        stack: error.stack,
        token: process.env.REACT_APP_BLOB_READ_WRITE_TOKEN ? 'Token exists' : 'No token'
      });
      return null;
    }
  };

  // Handles photo upload from file input
  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Size validation (4.5MB limit)
    if (file.size > 4.5 * 1024 * 1024) {
      toast.error('Please choose a file smaller than 4.5MB');
      return;
    }

    setIsUploading(true);
    const loadingToast = toast.loading('Uploading photo...');
    
    try {
      // Create preview using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        const previewUrl = reader.result;
        setUploadedPhoto(previewUrl);
        
        // Store minimal data in localStorage to avoid size limits
        const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
        const updatedDetails = {
          ...existingDetails,
          inspiration: {
            ...existingDetails.inspiration,
            // Don't store the full preview URL in localStorage
            hasPreview: true,
            inspirationLink
          }
        };
        
        try {
          localStorage.setItem('eventDetails', JSON.stringify(updatedDetails));
        } catch (error) {
          // Handle storage error silently - preview will still work
          logger.warning('LocalStorage update skipped', { reason: 'size limit' });
        }
      };
      reader.readAsDataURL(file);

      // Upload file to Vercel Blob storage
      const blobUrl = await uploadToVercelBlob(file);
      if (blobUrl) {
        logger.success('File uploaded', { url: blobUrl });
        // Update localStorage with blob URL
        const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
        const updatedDetails = {
          ...existingDetails,
          inspiration: {
            ...existingDetails.inspiration,
            blobUrl,
            inspirationLink
          }
        };
        localStorage.setItem('eventDetails', JSON.stringify(updatedDetails));
        
        toast.dismiss(loadingToast);
        toast.success('Photo uploaded successfully!');
      }
    } catch (error) {
      logger.error('Upload failed', error);
      toast.dismiss(loadingToast);
      toast.error('Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  // Validates URL format and structure
  const isValidUrl = (string) => {
    if (!string) return true; // Empty string is valid (optional field)
    
    // Check for valid domain extension
    if (!string.match(/\.[a-z]{2,}($|\/)/i)) {
      return false;
    }
    
    // Normalize URLs by adding https:// if missing
    let urlString = string;
    if (!string.match(/^(https?:\/\/|www\.)/i)) {
      urlString = 'https://' + string;
    }
    // Add https:// to www. URLs
    if (string.match(/^www\./i)) {
      urlString = 'https://' + string;
    }

    try {
      new URL(urlString); // Attempt to construct URL object
      return true;
    } catch (_) {
      return false;
    }
  };

  // Handles changes to inspiration link input
  const handleLinkChange = (e) => {
    const value = e.target.value;
    setInspirationLink(value);
    
    // Save to localStorage on each change
    const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    localStorage.setItem('eventDetails', JSON.stringify({
      ...existingDetails,
      inspiration: {
        ...existingDetails.inspiration,
        inspirationLink: value
      }
    }));
  };

  // Validates link on input blur
  const handleLinkBlur = () => {
    if (inspirationLink && !isValidUrl(inspirationLink)) {
      setLinkError('Please enter a valid website address (e.g., pinterest.com)');
    } else {
      setLinkError('');
    }
  };

  // Handles form submission and navigation for Airtable
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Save final state before navigation
    const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    console.log('Details before navigation:', existingDetails);
    
    localStorage.setItem('eventDetails', JSON.stringify({
      ...existingDetails,
      inspiration: {
        ...existingDetails.inspiration,
        inspirationLink: inspirationLink
      }
    }));
    
    navigate('/contact');
  };

  // Drag and drop event handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add('border-blue-400', 'bg-blue-100');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('border-blue-400', 'bg-blue-100');
  };

  // Handles file drop events
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('border-blue-400', 'bg-blue-100');
    
    const file = e.dataTransfer.files[0];
    if (file) {
      // Size validation
      if (file.size > 4.5 * 1024 * 1024) {
        setNotification({
          message: 'Please choose a file smaller than 4.5MB',
          type: 'error'
        });
        setTimeout(() => setNotification(null), 3000);
        return;
      }
      
      try {
        // File type and size validation
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validTypes.includes(file.type)) {
          alert('Please upload only JPEG, JPG or PNG files');
          return;
        }
        
        if (file.size > 4.5 * 1024 * 1024) {
          alert('File size must be less than 4.5MB');
          return;
        }

        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadedPhoto(reader.result);
        };
        reader.readAsDataURL(file);

        // Upload to blob storage
        const blobUrl = await uploadToVercelBlob(file);
        if (blobUrl) {
          const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
          localStorage.setItem('eventDetails', JSON.stringify({
            ...existingDetails,
            inspiration: {
              ...existingDetails.inspiration,
              blobUrl,
              inspirationLink: inspirationLink
            }
          }));
        }
      } catch (error) {
        console.error('Error in handleDrop:', error);
        alert('Failed to process image. Please try again.');
      }
    }
  };

  return (
    <MainLayout
      progressTracker={<ProgressTracker currentStep={4} />}
    >
      {/* Notification toast */}
      <AnimatePresence>
        {notification && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed top-4 right-4 z-50 max-w-sm"
          >
            <div className={`rounded-lg px-4 py-3 shadow-lg ${
              notification.type === 'error' 
                ? 'bg-white border-l-4 border-red-500'
                : 'bg-white border-l-4 border-blue-500'
            }`}>
              <div className="flex items-center">
                <div className="ml-3">
                  <p className={`text-sm font-medium ${
                    notification.type === 'error' 
                      ? 'text-red-800' 
                      : 'text-blue-800'
                  }`}>
                    {notification.message}
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main content container */}
      <motion.div 
        className="w-full mx-auto mt-6 bg-white rounded-3xl shadow-xl overflow-hidden px-4 sm:px-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        {/* Upload photo section */}
        <div className="mb-8 pt-6">
          <div className="mb-6 max-w-md mx-auto px-2 sm:px-0 space-y-3">
            <h2 className="text-2xl sm:text-3xl font-semibold text-gray-900">
              Upload a photo of what you want your event to look like
            </h2>
            <p className="text-gray-500 text-sm">
              Venues respond 40% faster when they can see your vision
            </p>
          </div>

          <AnimatePresence mode="wait">
            {/* Upload dropzone - shown when no photo is uploaded */}
            {!uploadedPhoto && (
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex justify-center mb-4 px-2 sm:px-0"
              >
                <motion.label
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  htmlFor="photo-upload"
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  className="w-full max-w-md p-4 sm:p-8 flex flex-col items-center gap-4 cursor-pointer 
                    rounded-xl border-2 border-dashed border-blue-300 bg-blue-50 
                    hover:bg-blue-100 hover:border-blue-400 transition-all duration-200"
                >
                  <span className="bg-blue-100 text-blue-600 p-4 rounded-full">
                    <Upload className="w-8 h-8" />
                  </span>
                  <div className="text-center">
                    <span className="text-blue-600 font-semibold text-lg block mb-1">
                      Upload inspiration photo
                    </span>
                    <span className="text-blue-400 text-sm block">
                      Click to browse or drag and drop
                    </span>
                    <span className="text-blue-400 text-xs block mt-1">
                      JPEG, JPG or PNG (max. 4.5MB)
                    </span>
                  </div>
                  <input
                    type="file"
                    id="photo-upload"
                    accept="image/jpeg,image/jpg,image/png"
                    onChange={handlePhotoUpload}
                    className="hidden"
                  />
                </motion.label>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Photo preview section */}
          <AnimatePresence>
            {uploadedPhoto && (
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="mb-8 relative mx-auto w-[300px]"
              >
                <div className="aspect-square relative overflow-hidden rounded-lg shadow-lg">
                  <img 
                    src={uploadedPhoto} 
                    alt="Uploaded inspiration" 
                    className="w-full h-full object-cover"
                  />
                  {/* Remove photo button */}
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setUploadedPhoto(null)}
                    className="absolute top-3 right-3 bg-white/90 backdrop-blur text-red-500 p-2 rounded-full hover:bg-white transition-all duration-200 shadow-md"
                  >
                    <X className="w-4 h-4" />
                  </motion.button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Inspiration link input with validation on blur */}
          <div className="mb-8 max-w-md mx-auto px-2 sm:px-0">
            <div className="relative">
              <input
                type="url"
                value={inspirationLink}
                onChange={handleLinkChange}
                onBlur={handleLinkBlur}
                placeholder="Or share a link"
                className="w-full p-3 sm:p-4 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-4"
              />
              <p className="mt-2 text-sm px-1 flex items-center gap-2">
                {linkError ? (
                  <span className="text-red-500">{linkError}</span>
                ) : (
                  <span className="text-gray-500">
                    Pinterest boards, public Instagram posts, or venue photos that match your vision.
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>

        {/* Submit button container with subtle message */}
        <div className="flex flex-col items-center gap-3 pb-6 px-2 sm:px-0">
          <motion.button
            onClick={handleSubmit}
            disabled={isUploading}
            className={`flex items-center gap-2 py-2.5 sm:py-3 px-6 sm:px-8 rounded-full font-medium
              transition-all duration-200 shadow-md hover:shadow-lg
              ${isUploading 
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-gradient-to-r from-emerald-400 to-emerald-600'} 
              text-white`}
          >
            {isUploading ? (
              <span>Uploading...</span>
            ) : (
              <>
                Continue
                <ChevronRight className="w-4 h-4" />
              </>
            )}
          </motion.button>
          <button
            onClick={handleSubmit}
            className="text-gray-500 text-sm hover:text-gray-700 transition-colors"
          >
            Skip for now
          </button>
        </div>
      </motion.div>
    </MainLayout>
  );
}
