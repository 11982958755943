/**
 * LandingPage Component
 * Main entry point for the application
 * Features:
 * - Occasion selection
 * - Example event cards with expandable details
 * - Animated transitions
 * - Responsive design
 * - Hero section with value proposition
 * - Real event examples
 */

import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import OccasionSelector from '../components/OccasionSelector';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import * as Tooltip from '@radix-ui/react-tooltip';

const HomeNavbar = () => (
  <div className="absolute w-full top-0 left-0">
    <Navbar />
  </div>
);

export default function LandingPage() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-[#F9F5FF] to-white relative">
      <style jsx global>{`
        nav {
          background: transparent !important;
          border: none !important;
          backdrop-filter: none !important;
        }
      `}</style>
      <HomeNavbar />
      <main className="flex-grow pt-20 pb-12 md:pb-16 lg:pb-24">
        <div className="max-w-[90rem] mx-auto px-6 sm:px-8 lg:px-12 mb-12 md:mb-16 lg:mb-20 min-h-[calc(100vh-80px)]">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-12">
            {/* Left side - Text and Selector */}
            <motion.div 
              className="w-4/5 text-left mx-auto lg:mx-0"
            >
              {/* Concierge Banner - single line with bold text */}
              <div className="mb-2 flex justify-end -mt-8">
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <div className="transform -rotate-2">
                        <div className="bg-gradient-to-r from-emerald-400 to-emerald-500 shadow-lg px-8 py-2.5 rounded-lg border-2 border-white hover:shadow-xl transition-all cursor-help inline-flex items-center gap-4 group hover:-translate-y-0.5">
                          <p className="text-white text-[13px] whitespace-nowrap">
                            Your <span className="font-bold">Personal Event Concierge</span>
                            <span className="ml-3 text-emerald-50">
                              ✨ $99
                            </span>
                          </p>
                        </div>
                      </div>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="bg-white px-4 py-2 rounded-lg shadow-lg text-sm text-gray-800 border border-gray-200"
                        sideOffset={5}
                      >
                        ✨ 100% refundable if you're not satisfied with the options
                        <Tooltip.Arrow className="fill-white" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>

              {/* Main Title */}
              <h1 className="text-[1.75rem] leading-tight sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-2 sm:mb-6">
                Describe your{' '}
                <span className="inline-block bg-gradient-to-r from-purple-600 to-violet-500 bg-clip-text text-transparent">
                  dream event
                </span>{' '}
                <span className="inline">we'll make it happen</span>
              </h1>

              <p className="text-gray-600 text-sm sm:text-lg font-medium mb-2 sm:mb-6">
                👇 Choose an occasion to get started
              </p>

              <div className="mb-2 sm:mb-8 w-full">
                <OccasionSelector />
              </div>

              {/* Value Proposition */}
              <div className="mt-4 sm:mt-8">
                <p className="text-sm sm:text-lg lg:text-xl text-gray-800 font-medium">
                  Your time is valuable. {' '}
                  <span className="relative inline-block">
                    Skip the endless Google search
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-400"></span>
                  </span>
                  {' '}— we'll handpick venues for you.
                </p>
              </div>

              {/* Mission Link */}
              <motion.a
                href="/mission"
                className="group inline-flex items-center gap-2 text-purple-600 hover:text-purple-700 text-base sm:text-lg mt-2 sm:mt-4"
                whileHover={{ x: 10 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <span className="relative">
                  See how we save you time
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-600 scale-x-0 group-hover:scale-x-100 transition-transform origin-left"></span>
                </span>
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </motion.a>

              {/* NYC Pill Indicator */}
              <div className="flex mt-4">
                <div className="inline-flex items-center px-3 py-1 rounded-full bg-gradient-to-r from-purple-100 to-violet-100 border border-purple-200">
                  <span className="text-purple-700 font-medium text-sm">🗽 NYC Only</span>
                </div>
              </div>
            </motion.div>

            {/* Right side - iPhone Image */}
            <motion.div 
              className="w-full lg:w-[40%] flex justify-center items-center flex-col"
            >
              <div className="relative w-[225px] md:w-[337px] max-w-full"> 
                <img 
                  src="/HomePageHeroPartyIPhone.png" 
                  alt="LetsHost Event Planning" 
                  className="w-full h-auto object-contain"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
